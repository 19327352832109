import { COMPUTER_THOUSAND, ComputerPrefixExponents } from '../interfaces/computer.interfaces';

export function convertToBytes(
  quantity: number,
  prefixExponent: ComputerPrefixExponents = ComputerPrefixExponents.MEGA,
): number {
  return Math.pow(COMPUTER_THOUSAND, prefixExponent) * quantity;
}

export function convertFromBytes(
  amount: number,
  prefixExponent: ComputerPrefixExponents = ComputerPrefixExponents.MEGA,
): number {
  return amount / Math.pow(COMPUTER_THOUSAND, prefixExponent);
}
