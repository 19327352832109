import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { AppType, BuilderInfoService } from '@portal/builder';

const FAVICON_URLS: (x: string) => Record<string, string> = (app = 'default') => ({
  'iphone-favicon': `assets/favicon/${app}/apple-touch-icon.png`,
  'standard-favicon': `assets/favicon/${app}/favicon-32x32.png`,
  'svg-favicon': `assets/favicon/${app}/favicon.svg`,
  'x-icon': `assets/favicon/${app}/favicon.ico`,
});

const includedApps: AppType[] = ['Abrod' as AppType];

export function addFaviconsPaths(): void {
  const document: Document = inject(DOCUMENT);
  const appType: AppType = inject(BuilderInfoService).appType;
  const appForPath: string = includedApps.includes(appType) ? appType.toLowerCase() : 'default';

  for (const [key, value] of Object.entries(FAVICON_URLS(appForPath))) {
    const element: HTMLElement = document.getElementById(key);
    element && element.setAttribute('href', value);
  }
}
