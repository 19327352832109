import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { RoleShared } from '@portal/models/roleShared';
import { AuthService } from '../auth.service';
import { PermissionsService } from '../permissions/permissions.service';
import PrivilegesEnum = RoleShared.PrivilegesEnum;

interface DataPermissions {
  data?: {
    permissions?: PrivilegesEnum[];
  };
}

type RouteWithPermissions = Route & DataPermissions;
type ActivatedRouteWithPermissions = ActivatedRouteSnapshot & DataPermissions;

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private readonly auth: AuthService,
    private readonly permissionsService: PermissionsService,
    private readonly router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteWithPermissions,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url;

    return this.hasAllPermissions(route) && this.checkLogIn(url);
  }

  canLoad(route: RouteWithPermissions, _segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const url = `/${route.path}`;

    return this.hasAllPermissions(route) && this.checkLogIn(url);
  }

  private hasAllPermissions(route: RouteWithPermissions | ActivatedRouteWithPermissions) {
    const permissions: PrivilegesEnum[] = route.data && route.data.permissions;
    if (!(permissions && permissions.length)) {
      return true;
    }

    if (permissions.every(p => this.permissionsService.hasPermission(p))) {
      return true;
    }

    this.router.navigate(['/']);

    return false;
  }

  private checkLogIn(url?: string): boolean {
    if (this.auth.isAuthenticated) {
      return true;
    }

    this.auth.redirectUrl = url;
    this.router.navigate(['/auth']);

    return false;
  }
}
