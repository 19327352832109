import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import { RegisterOrderRequest } from '@portal/models/registerOrderRequest';
import LanguageEnum = RegisterOrderRequest.LanguageEnum;

@Injectable({
  providedIn: 'root',
})
export class LangInterceptor implements HttpInterceptor {
  constructor(private readonly translateService: TranslateService) {}

  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<any>> {
    const data = this.setLangHeaderToRequest(request);

    return next.handle(data);
  }

  private setLangHeaderToRequest(request: HttpRequest<object>) {
    const lang = this.isAlreadyHasLang(request) && this.getAlreadySetLang(request);

    return request.clone({
      headers: request.headers.set('Request-Language', lang || this.getCurrentLang()),
    });
  }

  private isAlreadyHasLang(request: HttpRequest<BodyWithLang>) {
    return request.params.has('lang') || !!request.body?.lang;
  }

  private getAlreadySetLang(request: HttpRequest<BodyWithLang>) {
    return request.params.get('lang') || request.body?.lang;
  }

  private getCurrentLang() {
    const lang = this.translateService.currentLang;

    return lang || 'RU';
  }
}

interface BodyWithLang {
  lang?: LanguageEnum;
}
