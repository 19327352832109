import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[portalLet]' })
export class LetDirective {
  constructor(private readonly viewContainerRef: ViewContainerRef, private readonly templateRef: TemplateRef<null>) {}

  @Input()
  set portalLet(value: any) {
    const view: any = this.viewContainerRef.get(0);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (view && view.context) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      view.context.portalLet = value;
    } else {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef, { portalLet: value });
    }
  }
}
