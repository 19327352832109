/**
 * Order service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DisputeDecisionShared } from './disputeDecisionShared';
import { DisputeMessageShared } from './disputeMessageShared';
import { DisputeReasonShared } from './disputeReasonShared';

export interface DisputeShared { 
    id?: number;
    reasonId?: number;
    expectedDecision?: DisputeDecisionShared;
    reason?: DisputeReasonShared;
    expectedDecisionId?: number;
    orderId?: number;
    disputeStatus?: DisputeShared.DisputeStatusEnum;
    terminated?: boolean;
    moderatorInvited?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    expiredAt?: Date;
    messages?: Array<DisputeMessageShared>;
}
export namespace DisputeShared {
    export type DisputeStatusEnum = 'NEW' | 'UNDER_CONSIDERATION' | 'BUYER_CANCELLED' | 'MODERATOR_REJECTED' | 'RETURNED' | 'SELLER_ACCEPT' | 'SELLER_REJECTED' | 'SETTLED';
    export const DisputeStatusEnum = {
        NEW: 'NEW' as DisputeStatusEnum,
        UNDERCONSIDERATION: 'UNDER_CONSIDERATION' as DisputeStatusEnum,
        BUYERCANCELLED: 'BUYER_CANCELLED' as DisputeStatusEnum,
        MODERATORREJECTED: 'MODERATOR_REJECTED' as DisputeStatusEnum,
        RETURNED: 'RETURNED' as DisputeStatusEnum,
        SELLERACCEPT: 'SELLER_ACCEPT' as DisputeStatusEnum,
        SELLERREJECTED: 'SELLER_REJECTED' as DisputeStatusEnum,
        SETTLED: 'SETTLED' as DisputeStatusEnum
    };
}