import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  pure: true,
})
export class SearchPipe<T extends string | number> implements PipeTransform {
  transform(value: T[], arg?: T): boolean {
    if (!value || !arg) {
      return null;
    }

    return value.some((item: T) => item.toString().toLowerCase() === arg.toString().toLowerCase());
  }
}
