import { PromocodePaginationRequest } from '@portal/models/promocodePaginationRequest';
import { EntityTypes, JsonSchemaStringFormat, JsonSchemaType } from '../enums/fields-structure.enums';
import LangEnum = PromocodePaginationRequest.LangEnum;

export interface FieldsStructure {
  title: string;
  name: string;
  jsonNode: JsonSchemaBase;
  required: boolean;
  filter: boolean;
  entityType: EntityTypes;
  lang: LangEnum;
  id?: number;
}

export interface JsonSchemaString {
  type: JsonSchemaType.STRING;
  format?: JsonSchemaStringFormat.DATE;
}

export interface JsonSchemaNumber {
  type: JsonSchemaType.NUMBER;
}

export interface JsonSchemaBoolean {
  type: JsonSchemaType.BOOLEAN;
}

export interface JsonSchemaEnum {
  type: JsonSchemaType.STRING | JsonSchemaType.NUMBER;
  enum?: EnumArrayRecord[];
}

export interface JsonSchemaStringArray {
  type: JsonSchemaType.ARRAY;
  items?: {
    type: JsonSchemaType.STRING;
    maxItems?: number;
  };
}

export interface JsonSchemaNumberArray {
  type: JsonSchemaType.ARRAY;
  items?: {
    type: JsonSchemaType.NUMBER;
    maxItems?: number;
  };
}

export interface JsonSchemaArray {
  type: JsonSchemaType.ARRAY;
  items?: {
    type: JsonSchemaType.STRING | JsonSchemaType.NUMBER;
    enum: EnumArrayRecord[];
  };
}

export interface JsonSchemaObject {
  type: JsonSchemaType.OBJECT;
  properties?: Record<string, JsonSchemaBase>;
}

export type JsonSchemaBase =
  | JsonSchemaString
  | JsonSchemaNumber
  | JsonSchemaBoolean
  | JsonSchemaEnum
  | JsonSchemaStringArray
  | JsonSchemaNumberArray
  | JsonSchemaArray
  | JsonSchemaObject;

export interface FieldsStructureResult {
  value: Record<string, FieldsStructure>;
  success: boolean;
}

export type EnumArrayRecord = Record<LangEnum | string, (string | number)[]>;
