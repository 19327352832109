import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionResultBuyerProblemShared } from '@portal/models/actionResultBuyerProblemShared';
import { ActionResultVoid } from '@portal/models/actionResultVoid';
import { replaceId } from '@portal/core';
import { clientContext } from '../client.context';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private readonly http: HttpClient) {}

  getProblemBuyer(buyerId: number) {
    return this.http.get<ActionResultBuyerProblemShared>(replaceId(clientContext.problems, buyerId));
  }
  setProblemBuyer(buyerId: number, sellerId: number) {
    return this.http.post<ActionResultVoid>(replaceId(clientContext.problems, buyerId), { sellerId });
  }

  removeProblemBuyer(buyerId: number) {
    return this.http.delete<ActionResultVoid>(replaceId(clientContext.problems, buyerId));
  }
}
