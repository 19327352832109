import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError , map , switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonBuyerShared } from '@portal/models/commonBuyerShared';
import { LegalSellerShared } from '@portal/models/legalSellerShared';
import { InternalCallbackService } from './internal-callback.service';

enum CallbackType {
  Buyers = 'buyers',
  Sellers = 'sellers',
}
@UntilDestroy()
@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalCallbackComponent implements OnInit {
  constructor(
    private readonly internalCallbackService: InternalCallbackService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        map(params => params.get('type')),
        switchMap((type: CallbackType) => {
          const id = this.activatedRoute.snapshot.paramMap.get('id');
          switch (type) {
            case CallbackType.Sellers:
              return this.internalCallbackService.loadSeller(+id);
            case CallbackType.Buyers:
              return this.internalCallbackService.loadBuyer(+id);
            default:
              throw new Error('Unknown type');
          }
        }),
        catchError(err => {
          this.router.navigate(['/not-found']);

          return err;
        }),
        untilDestroyed(this),
      )
      .subscribe((res: LegalSellerShared | CommonBuyerShared): void => {
        const type: CallbackType = this.activatedRoute.snapshot.paramMap.get('type') as CallbackType;
        switch (type) {
          case CallbackType.Sellers:
            this.router.navigate(['/companies', (res as LegalSellerShared).companyId], { replaceUrl: true });
            break;
          case CallbackType.Buyers:
            this.router.navigate(['/users', (res as CommonBuyerShared).user?.id], { replaceUrl: true });
            break;
          default:
            throw new Error('Unknown type');
        }
      });
  }
}
