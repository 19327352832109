import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PopupDialogComponent } from './popup-dialog.component';

@NgModule({
  declarations: [PopupDialogComponent],
  imports: [MatDialogModule, MatButtonModule, TranslateModule, CommonModule, A11yModule],
  exports: [PopupDialogComponent],
})
export class PopupDialogModule {}
