/**
 * Events log service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AuditLogRecordDto { 
    id?: number;
    createdAt?: Date;
    ip?: string;
    requestPath?: string;
    requestMethod?: string;
    userLogin?: string;
    userFirstName?: string;
    userMiddleName?: string;
    userLastName?: string;
    userId?: number;
    description?: string;
    summary?: string;
    isAdministrationAction?: boolean;
    portalDeterminate?: AuditLogRecordDto.PortalDeterminateEnum;
    profileType?: AuditLogRecordDto.ProfileTypeEnum;
    methodArguments?: { [key: string]: any; };
    device?: string;
    screenResolution?: string;
    browser?: string;
    latitude?: number;
    longitude?: number;
    city?: string;
}
export namespace AuditLogRecordDto {
    export type PortalDeterminateEnum = 'COMMON' | 'PENSION' | 'FINANCE';
    export const PortalDeterminateEnum = {
        COMMON: 'COMMON' as PortalDeterminateEnum,
        PENSION: 'PENSION' as PortalDeterminateEnum,
        FINANCE: 'FINANCE' as PortalDeterminateEnum
    };
    export type ProfileTypeEnum = 'COMMON_BUYER' | 'COMMON_SELLER' | 'LEGAL_BUYER' | 'LEGAL_SELLER' | 'OPERATOR' | 'COMMON_AGENT' | 'LEGAL_AGENT' | 'UNKNOWN' | 'MODERATOR' | 'TECHNICAL_USER' | 'COURIER';
    export const ProfileTypeEnum = {
        COMMONBUYER: 'COMMON_BUYER' as ProfileTypeEnum,
        COMMONSELLER: 'COMMON_SELLER' as ProfileTypeEnum,
        LEGALBUYER: 'LEGAL_BUYER' as ProfileTypeEnum,
        LEGALSELLER: 'LEGAL_SELLER' as ProfileTypeEnum,
        OPERATOR: 'OPERATOR' as ProfileTypeEnum,
        COMMONAGENT: 'COMMON_AGENT' as ProfileTypeEnum,
        LEGALAGENT: 'LEGAL_AGENT' as ProfileTypeEnum,
        UNKNOWN: 'UNKNOWN' as ProfileTypeEnum,
        MODERATOR: 'MODERATOR' as ProfileTypeEnum,
        TECHNICALUSER: 'TECHNICAL_USER' as ProfileTypeEnum,
        COURIER: 'COURIER' as ProfileTypeEnum
    };
}