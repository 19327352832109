import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@portal/models/address';
import { addressToString } from '../../shared/address.utils';

@Pipe({
  name: 'portalAddress',
  pure: true,
})
export class PortalAddressPipe implements PipeTransform {
  transform(value?: Address): string | null {
    return addressToString(value);
  }
}
