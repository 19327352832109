const api = '/notification-service/api/external';

export const notificationsContext = {
  targetNotifications: `${api}/targetNotifications`,
  targetNotification: `${api}/targetNotifications/{id}`,
  getTemplates: `${api}/notifications/templates`,
  getPageTemplates: `${api}/notifications/templatesPage`,
  createTemplate: `${api}/notifications/templates`,
  getTemplateById: `${api}/notifications/templates/{id}`,
  updateTemplateById: `${api}/notifications/templates/{id}`,
  updateTemplateStatus: `${api}/notifications/templates/{id}/status`,
  deleteTemplateById: `${api}/notifications/templates/{id}`,
  getNotifications: `${api}/notifications`,
  getNotificationById: `${api}/notifications/{id}`,
  createNotification: `${api}/notifications`,
  updateNotification: `${api}/notifications/{id}`,
  updateNotificationStatus: `${api}/notifications/{id}/status`,
  getChannels: `${api}/notifications/channelsPage`,
  updateChannels: `${api}/notifications/channels/{id}`,
  getEvents: `${api}/notifications/events`,
};
