const orderService = '/order-service/rest/api/external';
const disputes = `${orderService}/disputes`;
const reasons = `${orderService}/disputeReasons`;
const decisions = `${orderService}/disputeDecisions`;
const disputeById = `${disputes}/{id}`;

export const disputesContext = {
  disputes: `${disputes}`,
  disputeById,
  moderatorDecision: `${disputeById}/moderatorDecision`,
  sellerDecision: `${disputeById}/sellerDecision`,
  moderatorInvite: `${disputeById}/moderatorInvite`,
  uploadAttachment: `${orderService}/disputeAttachments`,
  addMessage: `${disputeById}/messages`,
  reasons: {
    all: reasons,
    byId: `${reasons}/{id}`,
  },
  decisions: {
    all: decisions,
    byId: `${decisions}/{id}`,
  },
  settings: `${disputes}/settings`,
};
