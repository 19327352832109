import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Option } from '../..';

export class EnumsTranslation {
  dictionary: Record<string, string>;
  options: Option[];

  constructor(translation: Record<string, string>) {
    this.dictionary = translation;
    this.options = EnumsTranslation.getOptionsFromDictionary(translation);
  }

  static getOptionsFromDictionary(translation: Record<string, string>): { name: string; value: string }[] {
    return Object.entries(translation).map(([k, v]) => ({ name: v, value: k }));
  }

  static getDictionaryFromOptions(o: Option[]) {
    return o.reduce((acc, c) => ((acc[c.value] = c.name), acc), {} as Record<string, string>);
  }
}

export const getEnumsTranslation = (translation: Observable<Record<string, string>>) =>
  translation.pipe(map(v => new EnumsTranslation(v)));

export const filterEnumTranslation = (translation: EnumsTranslation, allowed: string[]): EnumsTranslation => ({
  options: translation.options.filter((option: Option) => allowed.includes(option.value)),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  dictionary: Object.fromEntries(Object.entries(translation.dictionary).filter(([key]) => allowed.includes(key))),
});
