import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

export const servicesRoutes: Route[] = [];

@NgModule({
  imports: [CommonModule, RouterModule],
})
export class ServicesModule {}
