import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RequestLoaderInterceptor } from '@portal/core';
import { ErrorHandlerInterceptor } from '../error-handler';
import { ApiInterceptor } from './api.interceptor';
import { EndpointInterceptor } from './endpoint.interceptor';
import { LangInterceptor } from './lang.interceptor';
import { MultipartFormInterceptor } from './multipart-form.interceptor';
import { RefreshTokenInterceptor } from './refresh-token.interceptor';

@NgModule({
  imports: [CommonModule],
  providers: [
    // Lang should be first, even if we have errors i18n should be loaded
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LangInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MultipartFormInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestLoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EndpointInterceptor,
      multi: true,
    },
  ],
})
export class AuthInterceptorsModule {}
