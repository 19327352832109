import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

// Should go after LangInterceptor in order not to add lang param to this kind of request
@Injectable()
export class MultipartFormInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isMultipartContentType(request)) {
      // Let browser calculate boundary itself
      return next.handle(this.deleteContentType(request));
    }

    return next.handle(request);
  }

  private deleteContentType(req: HttpRequest<unknown>, value = 'multipart/form-data') {
    return req.clone({ headers: req.headers.delete('Content-Type', value) });
  }
}

export function isMultipartContentType(request: HttpRequest<unknown>) {
  return request.headers.get('Content-Type') === 'multipart/form-data';
}
