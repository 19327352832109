import { AfterViewInit, Component } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseControlValueAccessorComponent } from './base-control-value-accessor';

// value of the form control that extends BaseControlValueAccessorInitComponent is set after the main form is created
export function markFormAsPristine(form: UntypedFormGroup | UntypedFormControl, delay = 500) {
  setTimeout(() => {
    form.markAsPristine();
  }, delay);
}

// If you want to delegate setting default form's values to value accessor
// use this component
@UntilDestroy()
@Component({
  selector: 'portal-base-control-value-accessor-init',
  template: '',
})
export abstract class BaseControlValueAccessorInitComponent
  extends BaseControlValueAccessorComponent
  implements AfterViewInit, ControlValueAccessor
{
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.form?.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
        this.propagateChange(value);
      });
      this.propagateChange(this.form?.value); // Emit default form value
      // TODO: make setting pristine working in parent form
    }, 0); // In order to avoid expression has changed after it was checked error
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line arrow-body-style
  private propagateChange = (..._: object[]): void => {
    return;
  };
}
