import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BuilderInfoService, CurrencyDisplayType } from '@portal/builder';

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  constructor(private readonly builderInfoService: BuilderInfoService) {}
  transform(currencyCode: string, format: 'wide' | 'narrow' = 'narrow', locale?: string): string {
    return this.builderInfoService.settings.currencyDisplayType === CurrencyDisplayType.CODE
      ? this.builderInfoService.currency
      : this.builderInfoService.currencySymbol ||
          getCurrencySymbol(currencyCode || this.builderInfoService.currency, format, locale).replace(/(\d)/, ' $1');
  }
}
