import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupDialogData } from './popup-dialog.interface';

@Component({
  selector: 'portal-dialog-popup',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss'],
})
export class PopupDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopupDialogData,
  ) {}

  cancelClick(): void {
    this.dialogRef.close();
  }
}
