/**
 * This file is replaced during build by using the `fileReplacements` array.
 * `ng build --prod` replaces `environment.ts` with an appropriate `environment.prod.ts` according to appType.
 * The list of file replacements can be found in `angular.json`.
 */

export const environment = {
  production: false,
  endpoints: {
    api: ' http://84.201.184.79:5556',
    images: ' http://84.201.184.79:9091/',
  },
};
