import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from './directives';
import { HintsModule } from './hints';
import { PipesModule } from './pipes';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    RouterModule,
    FormsModule,
    DirectivesModule,
    MatSnackBarModule,
    MatDialogModule,
    HintsModule,
  ],
  providers: [],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    RouterModule,
    FormsModule,
    DirectivesModule,
    HintsModule,

    MatSnackBarModule,
    MatDialogModule,
  ],
  declarations: [],
})
export class CoreModule {}
