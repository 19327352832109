import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { getApiEndpoint } from '@portal/api-endpoints';
import { AGENT_TYPE } from '../auth.constants';

const NoModifyUrlSegment = {
  http: 'http',
  https: 'https',
  assets: './assets',
};

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  envApi = getApiEndpoint(this.injector.get(AGENT_TYPE));

  constructor(private readonly injector: Injector) {}

  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<any>> {
    const noModificationAllowed = Object.values(NoModifyUrlSegment).some(segment => request.url.includes(segment));
    if (noModificationAllowed) {
      return next.handle(request);
    }

    const httpsReq = request.clone({
      url: this.envApi + request.url,
    });

    return next.handle(httpsReq);
  }
}
