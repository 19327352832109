import { Observable, Subject } from 'rxjs';

type PlainObject = any;

export class BasicStorageService implements Map<string, PlainObject> {
  readonly [Symbol.toStringTag]: 'Map';

  private readonly changesSubject: Subject<string> = new Subject<string>();
  readonly changes$: Observable<string> = this.changesSubject.asObservable();

  constructor(private readonly storage: Storage) {}

  get size(): number {
    return this.storage.length;
  }

  [Symbol.iterator](): IterableIterator<[string, PlainObject]> {
    return this.entries();
  }

  clear(): void {
    this.storage.clear();
  }

  delete(key: string): boolean {
    this.storage.removeItem(key);

    return this.has(key);
  }

  entries(): IterableIterator<[string, PlainObject]> {
    return Object.entries(this.storage).map(([key, value]) => [key, this.parseJSON(value)]) as any;
  }

  forEach(
    callbackfn: (value: PlainObject, key: string, map: Map<string, PlainObject>) => void,
    thisArg?: never
  ): void {
    return Array.from(this.entries()).forEach(
      ([key, value]) => callbackfn(value, key, this),
      thisArg
    );
  }

  get(key: string): PlainObject | undefined {
    return this.parseJSON(this.storage.getItem(key));
  }

  has(key: string): boolean {
    return this.get(key) != null;
  }

  keys(): IterableIterator<string> {
    return Object.keys(this.storage) as any;
  }

  set(key: string, value: PlainObject): this {
    this.storage.setItem(key, this.serializeObject(value));
    this.changesSubject.next(key);

    return this;
  }

  values(): IterableIterator<PlainObject> {
    return Object.values(this.storage).map((value: string) => this.parseJSON(value)) as any;
  }

  private parseJSON(json: string): PlainObject | undefined {
    try {
      return JSON.parse(json);
    } catch (e) {
      return undefined;
    }
  }

  private serializeObject(obj: PlainObject): string | undefined {
    try {
      return JSON.stringify(obj);
    } catch (e) {
      return undefined;
    }
  }
}
