export const dashboardsContext = {
  rating: '/review-service/api/external/reviews/sellers/{id}',
  sales: '/order-service/rest/api/external/dashboards/sales',
  orders: '/order-service/rest/api/external/dashboards/orders',
  ordersStatistics: '/order-service/rest/api/external/sellers/{id}/statistics',
  deliveries: '/order-service/rest/api/external/dashboards/orders/deliveries',
  orderDetails: '/order-service/rest/api/external/dashboards/orders/detailedGraph',
  products: '/item-service/api/external/items/statistics/commonInfo',
  topSellingProducts: '/order-service/rest/api/external/dashboards/topSellingItems',
  slaPerformance: '/order-service/rest/api/external/dashboards/slaPerformance',
  totalItemsSold: '/order-service/rest/api/external/dashboards/totalItemsSoldQuantity',
  totalItemsView: '/item-service/api/external/items/statistics/totalViews',
};
