import { Pipe, PipeTransform } from '@angular/core';
import { OrderResponse } from '@portal/models/orderResponse';
import { BuilderInfoService } from '@portal/builder';
import StatusEnum = OrderResponse.StatusEnum;

@Pipe({
  name: 'orderStatus',
  pure: true,
})
export class OrderStatusPipe implements PipeTransform {
  constructor(private readonly builderInfoService: BuilderInfoService) {}

  transform(value: StatusEnum): StatusEnum {
    const statusMapping: Record<StatusEnum, StatusEnum[]> = this.builderInfoService.settings?.statusesMap;
    if (!statusMapping) {
      return value;
    }

    const replacedStatus = Object.values(statusMapping || {}).find((v: StatusEnum[]) => v.includes(value));

    return (replacedStatus && replacedStatus?.[0]) || value;
  }
}
