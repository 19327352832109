import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

function updateValidationMessage(error: ValidationErrors, message: string): ValidationErrors {
  return Object.keys(error)
    .map(key => [key, message])
    .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {});
}

export const withMessage = (message: string, validator: ValidatorFn) => (control: UntypedFormControl) => {
  const validationError: ValidationErrors = validator(control);

  return validationError ? updateValidationMessage(validationError, message) : validationError;
};

export const dynamicValidator =
  (predicate: () => boolean, validator: () => ValidatorFn) => (formControl: AbstractControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return validator()(formControl);
    }

    return null;
  };

export const requiredIfValidator = (predicate: () => boolean) => dynamicValidator(predicate, () => Validators.required);

export function updateTreeValidity(group: UntypedFormGroup | UntypedFormArray): void {
  Object.values(group.controls).forEach((abstractControl: AbstractControl) => {
    if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
      updateTreeValidity(abstractControl);
    } else {
      abstractControl.updateValueAndValidity();
    }
  });
  group.updateValueAndValidity();
}

export const extractTouchedChanges = (control: AbstractControl): Observable<boolean> => {
  const prevMarkAsTouched = control.markAsTouched;
  const prevMarkAsUntouched = control.markAsUntouched;

  const touchedChangesSubject = new Subject<boolean>();

  control.markAsTouched = (...args) => {
    touchedChangesSubject.next(true);
    prevMarkAsTouched.apply(control, args);
  };

  control.markAsUntouched = (...args) => {
    touchedChangesSubject.next(false);
    prevMarkAsUntouched.apply(control, args);
  };

  return touchedChangesSubject.asObservable();
};
