import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[portalCalcFromFullHeight]',
})
export class CalculateFromFullHeightDirective implements OnInit, OnChanges {
  @Input() portalCalcFromFullHeight: number | number[];

  constructor(private readonly element: ElementRef, private readonly renderer: Renderer2) {}

  ngOnInit(): void {
    this.changeElementHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.portalCalcFromFullHeight.currentValue) {
      this.changeElementHeight();
    }
  }

  private changeElementHeight(): void {
    this.renderer.setStyle(this.element.nativeElement, 'height', `calc(100% - ${this.calculateCommonHeights()}px`);
  }

  private calculateCommonHeights(): number {
    if (!Array.isArray(this.portalCalcFromFullHeight)) {
      return this.portalCalcFromFullHeight;
    }

    return this.portalCalcFromFullHeight.reduce((prevValue, curValue) => prevValue + curValue, 0);
  }
}
