import { HttpParams } from '@angular/common/http';
import { getHttpParams } from '@portal/core';
import { LAST_OPERATIONS_NUMBER } from './users.constants';
import { OrdersBack } from './users.service.interfaces';

export function getOrdersRequest(id: number): HttpParams {
  const ordersReq: OrdersBack = {
    sellerIds: id.toString(),
    'page.num': 1,
    'page.size': LAST_OPERATIONS_NUMBER,
  };

  return getHttpParams(ordersReq);
}
