import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { clientContext } from '../client.context';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private readonly refreshTokenInProgressSubject = new BehaviorSubject(false);
  private readonly refreshTokenInProgress$ = this.refreshTokenInProgressSubject.asObservable();
  private readonly loginEndpoint = clientContext.token;

  constructor(private readonly auth: AuthService) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(this.addToken(request)).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status !== 401) {
          return throwError(() => err);
        }

        if (this.refreshTokenInProgressSubject.getValue()) {
          return this.putUpcomingRequestToQueue(next, request);
        }

        this.refreshTokenInProgressSubject.next(true);

        return this.renewAccessToken(next, request).pipe(
          catchError(() => {
            this.auth.logOut(true);
            return of(null);
          }),
        );
      }),
    );
  }

  private canAttachToken<T>(request: HttpRequest<T>): boolean {
    return !request.url.includes(this.loginEndpoint) && !!this.auth.getAuthHeader();
  }

  private putUpcomingRequestToQueue<T>(next: HttpHandler, request: HttpRequest<T>): Observable<HttpEvent<T>> {
    return this.refreshTokenInProgress$.pipe(
      filter((inProgress: boolean) => !inProgress),
      distinctUntilChanged(),
      take(1),
      switchMap(() => next.handle(this.addToken(request))),
    );
  }

  private addToken<T>(request: HttpRequest<T>): HttpRequest<T> {
    if (this.canAttachToken(request)) {
      return request.clone({
        setHeaders: {
          Authorization: this.auth.getAuthHeader(),
        },
      });
    }
    return request;
  }

  private renewAccessToken<T>(next: HttpHandler, request: HttpRequest<T>): Observable<HttpEvent<T>> {
    return this.auth.renewAccessTokenSilently().pipe(
      switchMap(() => next.handle(this.addToken(request))),
      finalize(() => this.refreshTokenInProgressSubject.next(false)),
    );
  }
}
