const api = '/item-service/api/external';
const categories = `${api}/categories`;

export const categoriesContext = {
  all: categories,
  byId: `${categories}/{id}`,
  image: `${categories}/{id}/image`,
  relatedItemSet: `${categories}/{id}/relatedItemSet`,
  properties: {
    all: `${api}/category/properties`,
    byId: `${api}/category/properties/{id}`,
  },
};
