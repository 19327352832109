import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolean',
  pure: true,
})
export class BooleanPipe implements PipeTransform {
  transform(value: string): boolean {
    return value === 'true';
  }
}
