import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class EndpointInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    if (!this.isEndingWithSlash(request.url)) {
      return next.handle(request);
    }
    request = request.clone({
      url: request.url.slice(0, -1),
    });

    return next.handle(request);
  }

  private isEndingWithSlash(url: string) {
    return url.endsWith('/');
  }
}
