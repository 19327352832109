import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[portalDefaultImageOnError]',
})
export class DefaultImageOnErrorDirective {
  @Input() portalDefaultImageOnError: string;

  @HostListener('error', ['$event'])
  handleError(event: ErrorEvent): void {
    (event.target as HTMLImageElement).src = this.portalDefaultImageOnError;
  }
}
