export interface FieldsStructureEndpointsSchema {
  orders: string;
  companies: string;
  items: string;
}

export interface AdditionalFieldsContext {
  fields: string;
  fieldById: string;
}

// service name should be added to the beginning of the uel below
export const additionalFieldsContext: AdditionalFieldsContext = {
  fields: `/api/external/fieldsStructure`,
  fieldById: `/api/external/fieldsStructure/{id}`,
};
