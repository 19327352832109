import { registerLocaleData } from '@angular/common';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import _moment from 'moment';
import { BuilderInfoService, Locale, Theme } from '@portal/builder';

@Injectable({
  providedIn: 'root',
})
export class AppInitializationService {
  private readonly renderer: Renderer2;

  constructor(private readonly builderInfoService: BuilderInfoService, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  async initApp(): Promise<unknown> {
    return new Promise<void>((resolve, reject): void => {
      this.loadLocaleConfig(this.builderInfoService.locale).then(module => {
        registerLocaleData(module.default);
        _moment.locale(this.builderInfoService.locale);
        this.setTheme();
        resolve();
      }, reject);
    });
  }

  private setTheme(): void {
    const html = document.documentElement;

    // Only a single class of theme can be set
    Object.values(Theme).map((themeValue: Theme) => {
      this.renderer.removeClass(html, themeValue);
    });

    this.renderer.addClass(html, this.builderInfoService.theme);
  }

  private async loadLocaleConfig(locale: Locale): Promise<{ default: object }> {
    switch (locale) {
      case Locale.RU:
        return import('@angular/common/locales/ru');
      case Locale.FR:
        return import('@angular/common/locales/fr');
      case Locale.AR:
        return import('@angular/common/locales/ar');
      case Locale.HI:
        return import('@angular/common/locales/en-IN');
      default:
        return import('@angular/common/locales/en');
    }
  }
}
