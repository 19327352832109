import { sharedContext } from '../../endpoints/shared.context';

/**
 * @packageDocumentation
 * This file sets default configuration that can be overwritten by properties from runTimeConfig.json file.
 */

export const environment = {
  production: false,
  endpoints: {
    ...sharedContext,
    api: '  https://gateway.semenatest.bpcmarketplace.com',
    images: ' https://content.semenatest.bpcmarketplace.com/',
  },
};
