import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@portal/services';

@Injectable({
  providedIn: 'root'
})
export class LocationStorageService {
  private readonly LOCATION_KEY = 'location';
  private readonly  localStorage:LocalStorageService;

    constructor(
    private readonly router: Router
  ) {
      this.localStorage = inject(LocalStorageService);
    }

  store(url: string): void {
    this.localStorage.set(this.LOCATION_KEY, url);
  }

  get(): string | null {
    return this.localStorage.get(this.LOCATION_KEY);
  }

  async restore(): Promise<void> {
    try {
      const url: string | null = this.get();

      await this.router.navigateByUrl(url || '/');
    } finally {
      this.clear();
    }
  }

  clear(): void {
    this.localStorage.delete(this.LOCATION_KEY);
  }
}
