// tslint:disable-next-line:nx-enforce-module-boundaries
import { AppType } from '../../../../../builder/src/lib/builder.interface';
import { AvailableAppEndpoints } from '../evironment.interface';

/** {@link AppEnvironments} contains just possible api and should be used as a handbook */
export const AvailableAppsEndpoints: AvailableAppEndpoints = {
  [AppType.Gdesemena]: {
    test: {
      api: ' https://auth-semena-test.bpcgroup.dev',
      images: 'https://content-semena-test.bpcgroup.dev/',
    },
    production: {
      api: 'https://auth.gdesemena.ru',
      images: 'https://content.gdesemena.ru/',
    },
    preprod: {
      api: 'https://gateway.semenapreprod.bpcmarketplace.com',
      images: 'https://content.semenapreprod.bpcmarketplace.com/',
    },
  },
  [AppType.Safal]: {
    test: {
      replaceable: {
        wltCode: 'WT-1134',
        wltUrl: 'https://demo.b2biz.co.in',
      },
      api: 'https://gateway.safaltest.bpcmarketplace.com',
      images: 'https://content.safaltest.bpcmarketplace.com/',
    },
    preprod: {
      replaceable: {
        wltCode: 'WT-1039',
        wltUrl: 'https://www.b2biz.co.in',
      },
      api: 'http://35.228.231.161:5556',
      images: 'http://35.228.231.161:9091/',
    },
    production: {
      replaceable: {
        wltCode: 'WT-1039',
        wltUrl: 'https://www.b2biz.co.in',
      },
      api: 'https://auth.safalfasalonline.in',
      images: 'https://content.safalfasalonline.in/',
    },
  },
  [AppType.Stc]: {
    test: {
      replaceable: {
        wltCode: 'WT-1134',
        wltUrl: 'https://demo.b2biz.co.in',
      },
      api: 'http://35.228.113.213:5556',
      images: 'http://35.228.113.213:9091/',
    },
    preprod: {
      replaceable: {
        wltCode: 'WT-1039',
        wltUrl: 'https://www.b2biz.co.in',
      },
      api: 'http://35.228.231.161:5556',
      images: 'http://35.228.231.161:9091/',
    },
    production: {
      replaceable: {
        wltCode: 'WT-1039',
        wltUrl: 'https://www.b2biz.co.in',
      },
      api: 'https://auth.safalfasalonline.in',
      images: 'https://content.safalfasalonline.in/',
    },
  },
  [AppType.Rshb]: {
    test: {
      api: 'http://10.7.51.16:5556',
      images: 'http://10.7.51.16:8091/',
    },
    preprod: {
      api: 'http://84.201.142.58:5556',
      images: 'http://84.201.142.58:8091/',
    },
    production: {
      api: 'https://auth.rbsgate.com',
      images: 'https://content.rbsgate.com/',
    },
  },
};
