export const pagesClientContext = {
  createPage: '/cms-service/api/external/pages',
  createVariable: '/cms-service/api/external/pageVariables',
  deletePage: '/cms-service/api/external/pages/{id}',
  deletePages: '/cms-service/api/external/pages',
  deleteVariable: '/cms-service/api/external/pageVariables/{id}',
  getPageById: '/cms-service/api/external/pages/{id}',
  getPages: '/cms-service/api/external/pages',
  getPublishedEndpoints: '/cms-service/api/external/pages/endpoints',
  getVariablesSchema: '/cms-service/api/external/variableTypes',
  updatePage: '/cms-service/api/external/pages/{id}',
  updatePageStatus: '/cms-service/api/external/pages/{id}/status',
  updateVariable: '/cms-service/api/external/pageVariables/{id}',
} as PagesEndpointsSchema;

interface PagesEndpointsSchema {
  getPages: string;
  createPage: string;
  deletePages: string;
  getPageById: string;
  updatePage: string;
  deletePage: string;
  updatePageStatus: string;
  getPublishedEndpoints: string;
  getVariablesSchema: string;
  createVariable: string;
  updateVariable: string;
  deleteVariable: string;
}
