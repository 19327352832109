/**
 * Order service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateSlaThresholdRequest { 
    sellerId?: number;
    status?: CreateSlaThresholdRequest.StatusEnum;
    maxDuration: string;
}
export namespace CreateSlaThresholdRequest {
    export type StatusEnum = 'CREATED' | 'AWAITING_CONFIRMATION' | 'AWAITING_PAYMENT' | 'CONFIRMED_PAYMENT' | 'PARTIALLY_PAID' | 'SHIPPED' | 'DELIVERED' | 'ISSUED' | 'CANCELED' | 'CANCELED_BY_SELLER' | 'CANCELED_BY_OPERATOR' | 'AWAITING_ASSEMBLY' | 'ASSEMBLY' | 'ASSEMBLED' | 'WAITING_DELIVERY' | 'RETURNED' | 'TIMEOUT' | 'BUY_IN_CREDIT';
    export const StatusEnum = {
        CREATED: 'CREATED' as StatusEnum,
        AWAITINGCONFIRMATION: 'AWAITING_CONFIRMATION' as StatusEnum,
        AWAITINGPAYMENT: 'AWAITING_PAYMENT' as StatusEnum,
        CONFIRMEDPAYMENT: 'CONFIRMED_PAYMENT' as StatusEnum,
        PARTIALLYPAID: 'PARTIALLY_PAID' as StatusEnum,
        SHIPPED: 'SHIPPED' as StatusEnum,
        DELIVERED: 'DELIVERED' as StatusEnum,
        ISSUED: 'ISSUED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum,
        CANCELEDBYSELLER: 'CANCELED_BY_SELLER' as StatusEnum,
        CANCELEDBYOPERATOR: 'CANCELED_BY_OPERATOR' as StatusEnum,
        AWAITINGASSEMBLY: 'AWAITING_ASSEMBLY' as StatusEnum,
        ASSEMBLY: 'ASSEMBLY' as StatusEnum,
        ASSEMBLED: 'ASSEMBLED' as StatusEnum,
        WAITINGDELIVERY: 'WAITING_DELIVERY' as StatusEnum,
        RETURNED: 'RETURNED' as StatusEnum,
        TIMEOUT: 'TIMEOUT' as StatusEnum,
        BUYINCREDIT: 'BUY_IN_CREDIT' as StatusEnum
    };
}