import { AbstractControl, ValidationErrors } from '@angular/forms';

export class DateValidators {
  static lowerCurrentDate(control: AbstractControl): ValidationErrors | null {
    const value: Date = control?.value;
    if (!value || !(value instanceof Date)) {
      return null;
    }

    return value.getTime() >= new Date().getTime() ? { exceededDate: true } : null;
  }
}
