export const courierContext = {
  courier: {
    getCouriers: '/user-service/api/external/couriers',
  },
  dbs: {
    setCourier: `/logistics-service/api/external/fullyDeliverySupport/orders/{id}/appointCourier`,
    getOrders: '/logistics-service/api/external/fullyDeliverySupport/orders',
    getOrderById: '/logistics-service/api/external/fullyDeliverySupport/orders/{id}',
    statusFlow: '/logistics-service/api/external/fullyDeliverySupport/orders/statusFlow',
    changeStatus: '/logistics-service/api/external/fullyDeliverySupport/orders/{id}/status',
  },
  coordinates: {
    getCoordinates: '/logistics-service/api/external/fullyDeliverySupport/courierCoordinates',
  },
};
