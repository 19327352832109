const api = '/report-service/api/external/reports';

export const reportsContext = {
  getReports: `${api}`,
  getReport: `${api}/{id}`,
  getSalesReport: `${api}/sales`,
  getProductsReport: `${api}/products`,
  getOrdersSummarized: `${api}/ordersSummarized`,
  getOrdersDetailed: `${api}/ordersDetailed`,
  getOrdersSettlementStatus: `${api}/ordersSettlementStatus`,
  getClientsReport: `${api}/clients`,
  getTrainings: `${api}/training`,
  getCompaniesReport: `${api}/companies`,
  getEmployeesReport: `${api}/employees`,
  partnersPurchases: `${api}/partnersPurchases`,
  transfers: `${api}/transfers`,
  operators: `${api}/operators`,
  promoCodes: `${api}/promocodes`,
  financialClaim: `${api}/financialClaims`,
  metricsData: `${api}/metrics`,
  preorders: `${api}/preOrders`,
  delivery: `${api}/delivery`,
  ordersRegistry: `${api}/ordersRegistry`,
  returns: `${api}/returns`,
  renting: `${api}/renting`,
};
