const api = '/item-service/api/external/views';
export const menuBuilderContext = {
  all: `${api}`,
  byId: `${api}/{id}`,
  loadById: `${api}/structure`,
  itemId: `${api}/structure/{id}`,
  itemIcon: `${api}/structure/{id}/icon`,
  itemImage: `${api}/structure/{id}/image`,
  itemBanners: `${api}/structure/banners`,
  itemBanner: `${api}/structure/banners/{id}`,
  itemBannerImage: `${api}/structure/banners/{id}/image`,
  templates: `${api}/templates`,
  templateById: `${api}/templates/{id}`,
  templateImage: `${api}/templates/{id}/previewImage`,
};
