import { RoleSaveRequest } from '@portal/models/roleSaveRequest';
import { environment as courierEnvironment } from './courier/environment';
import { environment as merchantEnvironment } from './merchant/environment';
import { environment as operatorEnvironment } from './operator/environment';
import ProfileTypeEnum = RoleSaveRequest.ProfileTypeEnum;
import { inject } from '@angular/core';
import { AGENT_TYPE } from '@portal/auth';

const ApiMap = {
  [ProfileTypeEnum.LEGALSELLER]: merchantEnvironment.endpoints,
  [ProfileTypeEnum.OPERATOR]: operatorEnvironment.endpoints,
  [ProfileTypeEnum.COURIER]: courierEnvironment.endpoints,
};

export function getImagesEndpoint(agentType: ProfileTypeEnum): string {
  return ApiMap[agentType].images;
}

export function getApiEndpoint(agentType: ProfileTypeEnum): string {
  return ApiMap[agentType].api;
}

export const getApiEndpointByProfileType = () => ApiMap[inject(AGENT_TYPE)].images;
