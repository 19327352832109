import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ProfileSettingsService } from './profile-settings.service';

@Injectable()
export class ProfileSettingsGuard implements CanActivate {
  constructor(private readonly profileSettingsService: ProfileSettingsService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.profileSettingsService.getSettings().pipe(map(v => v.success));
  }
}
