// tslint:disable-next-line:nx-enforce-module-boundaries
import { Item } from '../../../../models/item';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { PageContentBlockV2Shared } from '../../../../models/pageContentBlockV2Shared';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { PromocodePaginationRequest } from '../../../../models/promocodePaginationRequest';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { RoleSaveRequest } from '../../../../models/roleSaveRequest';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { SkuItemShared } from '../../../../models/skuItemShared';
import { AppType, BuilderEnvironment, LanguageLocale, RelatedItemPermissionLevel, Theme } from '../builder.interface';
// tslint:disable-next-line:nx-enforce-module-boundaries
import ProductTypeEnum = Item.ProductTypeEnum;
import ElementTypeEnum = PageContentBlockV2Shared.ElementTypeEnum;
import LangEnum = PromocodePaginationRequest.LangEnum;
import ProfileTypeEnum = RoleSaveRequest.ProfileTypeEnum;
import CurrencyEnum = SkuItemShared.CurrencyEnum;

type AvailableBuilderEnv = Record<
  AppType,
  {
    test: BuilderEnvironment;
    production: BuilderEnvironment;
  }
>;

type BaseBuilderEnv = Record<AppType, BuilderEnvironment>;

const baseLogosPath = 'assets/images/logos/';

export const BaseBuilderEnvs: BaseBuilderEnv = {
  [AppType.Gdesemena]: {
    appType: AppType.Gdesemena,
    currency: CurrencyEnum.RUB,
    locale: LanguageLocale.ru,
    currencySymbol: '₽',
    currencySettings: [
      {
        active: true,
        conversionRatio: 1,
        format: 'ru',
        currencySymbol: '₽',
        currency: CurrencyEnum.RUB,
      },
    ],
    country: 'RUS',
    timeOut: 50000,
    systemLang: LangEnum.RU,
    phoneSettings: [{ active: true, phoneLength: 10, phonePrefix: 7 }],
    langSetting: {
      defaultLang: LangEnum.RU,
      availableLangs: [LangEnum.RU, LangEnum.EN],
    },
    assets: {
      logo: {
        sidebar: {
          normal: baseLogosPath + 'default/lilac-logo-light.svg',
          light: baseLogosPath + 'default/lilac-logo-light.svg',
          small: baseLogosPath + 'default/lilac-logo-small.svg',
        },
        authPage: {
          normal: baseLogosPath + 'default/lilac-logo.svg',
        },
      },
    },
    termsOfUseUrl: 'https://gdesemena.ru/merchant_oferta.pdf',
    privacyPolicyUrl: 'https://gdesemena.ru/our_details.pdf',
    defaultCoordinates: {
      latitude: 55.7558,
      longitude: 37.6173,
    },
    accessTieredPrices: true,
    disabledProfiles: [ProfileTypeEnum.COMMONAGENT, ProfileTypeEnum.LEGALAGENT],
    allowedProductTypes: [ProductTypeEnum.DIGITAL, ProductTypeEnum.PHYSICAL],
    pageBuilderElements: [
      ElementTypeEnum.CUSTOM,
      ElementTypeEnum.ITEM,
      ElementTypeEnum.CATEGORY,
      ElementTypeEnum.TAG,
      ElementTypeEnum.ARTICLE,
    ],
    manageManualDelivery: true,
    disableCommonLogin: true,
    theme: Theme.LILAC,
    allowIndividualPrices: true,
    manageSellerRelatedItems: RelatedItemPermissionLevel.Bulk,
  },
  [AppType.Rshb]: {
    appType: AppType.Rshb,
    currency: CurrencyEnum.RUB,
    locale: LanguageLocale.ru,
    currencySymbol: '₽',
    currencySettings: [
      {
        active: true,
        conversionRatio: 1,
        format: 'ru',
        currencySymbol: '₽',
        currency: CurrencyEnum.RUB,
      },
    ],
    country: 'Russia',
    timeOut: 50000,
    systemLang: LangEnum.RU,
    phoneSettings: [{ active: true, phoneLength: 10, phonePrefix: 7 }],
    langSetting: {
      defaultLang: LangEnum.RU,
      availableLangs: [LangEnum.RU, LangEnum.EN],
    },
    allowedProductTypes: [ProductTypeEnum.DIGITAL, ProductTypeEnum.PHYSICAL, ProductTypeEnum.FINANCIALITEM],
    pageBuilderElements: [
      ElementTypeEnum.CUSTOM,
      ElementTypeEnum.FINANCIALITEM,
      ElementTypeEnum.CATEGORY,
      ElementTypeEnum.ARTICLE,
      ElementTypeEnum.PARTNER,
      ElementTypeEnum.PARTNERPRODUCT,
    ],
    assets: {
      logo: {
        sidebar: {
          normal: baseLogosPath + 'default/lilac-logo-light.svg',
          light: baseLogosPath + 'default/lilac-logo-light.svg',
          small: baseLogosPath + 'default/lilac-logo-small.svg',
        },
        authPage: {
          normal: baseLogosPath + 'default/lilac-logo.svg',
        },
      },
    },
    theme: Theme.LILAC,
    disableCommonLogin: true,
    allowIndividualPrices: true,
  },
  [AppType.Safal]: {
    appType: AppType.Safal,
    currency: CurrencyEnum.INR,
    locale: LanguageLocale.ru,
    currencySymbol: '₹',
    currencySettings: [
      {
        active: true,
        conversionRatio: 1,
        format: 'ru',
        currencySymbol: '₹',
        currency: CurrencyEnum.INR,
      },
    ],
    country: 'India',
    timeOut: 50000,
    systemLang: LangEnum.EN,
    phoneSettings: [{ active: true, phoneLength: 10, phonePrefix: 91 }],
    langSetting: {
      defaultLang: LangEnum.RU,
      availableLangs: [LangEnum.HI, LangEnum.EN],
    },
    assets: {
      logo: {
        sidebar: {
          normal: baseLogosPath + 'default/lilac-logo-light.svg',
          light: baseLogosPath + 'default/lilac-logo-light.svg',
          small: baseLogosPath + 'default/lilac-logo-small.svg',
        },
        authPage: {
          normal: baseLogosPath + 'safal/logo.svg',
        },
      },
    },
    termsOfUseUrl: 'https://safalfasalonline.in/terms_of_use.pdf',
    privacyPolicyUrl: 'https://safalfasalonline.in/privacy_policy.pdf',
    allowedProductTypes: [ProductTypeEnum.DIGITAL, ProductTypeEnum.PHYSICAL],
    pageBuilderElements: [ElementTypeEnum.CUSTOM, ElementTypeEnum.ITEM, ElementTypeEnum.CATEGORY, ElementTypeEnum.TAG],
    theme: Theme.LILAC,
    disableCommonLogin: true,
    allowIndividualPrices: true,
  },
  [AppType.Stc]: {
    appType: AppType.Stc,
    currency: CurrencyEnum.USD,
    locale: LanguageLocale.en,
    currencySymbol: '$',
    currencySettings: [
      {
        active: true,
        conversionRatio: 1,
        format: 'en',
        currencySymbol: '$',
        currency: CurrencyEnum.USD,
      },
    ],
    country: 'Saudi Arabia',
    timeOut: 50000,
    systemLang: LangEnum.EN,
    phoneSettings: [{ active: true, phoneLength: 10, phonePrefix: 7 }],
    langSetting: {
      defaultLang: LangEnum.RU,
      availableLangs: [LangEnum.EN],
    },
    allowedProductTypes: [ProductTypeEnum.DIGITAL, ProductTypeEnum.PHYSICAL],
    assets: {
      logo: {
        sidebar: {
          normal: baseLogosPath + 'stc/logo.svg',
          light: baseLogosPath + 'stc/logo-light.svg',
          small: baseLogosPath + 'stc/logo-small.svg',
        },
        authPage: {
          normal: baseLogosPath + 'stc/logo.svg',
        },
      },
    },
    theme: Theme.STC,
    disableCommonLogin: true,
  },
};

export const AvailableBuildEnvironments: AvailableBuilderEnv = {
  [AppType.Rshb]: {
    test: {
      ...BaseBuilderEnvs[AppType.Rshb],
    },
    production: {
      ...BaseBuilderEnvs[AppType.Rshb],
    },
  },
  [AppType.Gdesemena]: {
    test: {
      ...BaseBuilderEnvs[AppType.Gdesemena],
    },
    production: {
      ...BaseBuilderEnvs[AppType.Gdesemena],
    },
  },
  [AppType.Safal]: {
    test: {
      ...BaseBuilderEnvs[AppType.Safal],
    },
    production: {
      ...BaseBuilderEnvs[AppType.Safal],
    },
  },
  [AppType.Stc]: {
    test: {
      ...BaseBuilderEnvs[AppType.Stc],
    },
    production: {
      ...BaseBuilderEnvs[AppType.Stc],
    },
  },
};
