export const returnsContext = {
  getByPage: '/web-api/orders/refund',
  comment: '/order-service/rest/api/external/orders/{id}/comment',
  getReturns: `/order-service/rest/api/external/returns`,
  getReturn: '/order-service/rest/api/external/returns/{id}',
  getReceipt: '/fiscalization-service/api/external/receipt/',
  operatorInvite: `/order-service/rest/api/external/returns/{id}/inviteOperator`,
  performActionActive: '/order-service/rest/api/external/orders/{id}/performAction',
  performAction: `/order-service/rest/api/external/returns/{id}/performAction`,
  addAttachment: `/order-service/rest/api/external/returns/attachments`,
  openBadges: `/order-service/rest/api/external/dashboards/returns`,
  reasons: {
    getReasons: `/order-service/rest/api/external/returnReasons`,
    getReason: `/order-service/rest/api/external/returnReasons/{id}`,
  },
  messages: {
    sendMessage: `/order-service/rest/api/external/returns/{id}/messages`,
  },
  offer: {
    sendOffer: `/order-service/rest/api/external/returns/{id}/offer`,
  },
};
