import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { SerializedRouterState } from './router.serializer.interface';

export class PortalRouterStateSerializer implements RouterStateSerializer<SerializedRouterState> {
  serialize(routerState: RouterStateSnapshot): SerializedRouterState {
    const { url } = routerState;
    const queryParams = routerState.root.queryParams;
    const params = getMergedChildRouteParams(routerState.root);

    return { url, queryParams, params };
  }
}

function getMergedChildRouteParams(activatedRoute: ActivatedRouteSnapshot): Record<string, string> {
  const { params } = activatedRoute;
  const firstChild = activatedRoute.firstChild;

  return firstChild ? { ...params, ...getMergedChildRouteParams(firstChild) } : { ...params };
}
