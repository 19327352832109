export const marketingCompaniesBadgesClientContext = {
  add: '/item-service/api/external/badges',
  delete: '/item-service/api/external/badges/{id}',
  getAll: '/item-service/api/external/badges',
  update: '/item-service/api/external/badges/{id}',
  addBadgesForProduct: '/item-service/api/external/badges/byItems',
  deleteBadgesForProduct: '/item-service/api/external/badges/byItems',
  getBadgesForProducts: '/item-service/api/external/badges/byItems',
  getTemplates: '/item-service/api/external/badges/templates',
  productTemplate: '/item-service/api/external/catalog/badgeTemplate',
};
