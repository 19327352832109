import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectionDirective } from './direction/direction.directive';
import { HideInAppDirective } from './show-in-app/hide-in-app.directive';
import { ShowInAppDirective } from './show-in-app/show-in-app.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ShowInAppDirective, DirectionDirective, HideInAppDirective],
  exports: [ShowInAppDirective, DirectionDirective, HideInAppDirective],
})
export class BuilderModule {}
