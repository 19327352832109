import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'humanBoolean',
  pure: true,
})
export class HumanBooleanPipe implements PipeTransform {

  constructor(private readonly translateService: TranslateService) {
  }

  transform(value: boolean): Observable<string> {
    const translation$: Observable<string> =
      value
        ? this.translateService.get('shared.yes')
        : !value
        ? this.translateService.get('shared.no')
        : of('-');

    return translation$.pipe(take(1));
  }
}
