const pageContent = '/cms-service/api/external/pagesContent';

export const dynamicBlocksClientContext = {
  getPages: `${pageContent}`,
  getBlocks: `${pageContent}/page/{id}/blocks`,
  blockById: `${pageContent}/blocks/{id}`,
  blockElements: `${pageContent}/blocks/{id}/elements`,
  elementById: `${pageContent}/elements/{id}`,
  elementImage: `${pageContent}/elements/{id}/image`,
  blockImage: `${pageContent}/blocks/{id}/image`,
  blockStatus: `${pageContent}/blocks/{id}/status`,
  elementStatus: `${pageContent}/elements/{id}/status`,
  elements: `/cms-service/api/external/elements`,
  changeOrder: `${pageContent}t/blocks/{id}/changeOrder`,
  blockTemplates: `${pageContent}/blocks/{id}/templates`,
  allTags: '/item-service/api/external/tagsList',
};
