export const reviewsContext = {
  get: '/review-service/api/external/reviews',
  getById: '/review-service/api/external/reviews/{id}',
  changeStatus: '/review-service/api/external/reviews/{id}/status',
  getEntities: '/review-service/api/external/entities',
  getEntityById: '/review-service/api/external/entities/{id}',
  crateEntity: '/review-service/api/external/entities/',
  updateEntity: '/review-service/api/external/entities/{id}',
  deleteEntity: '/review-service/api/external/entities/{id}',
  getTemplates: '/review-service/api/external/reviewTemplates',
  getTemplateById: '/review-service/api/external/reviewTemplates/{id}',
  createTemplate: '/review-service/api/external/reviewTemplates/',
  updateTemplate: '/review-service/api/external/reviewTemplates/{id}',
  deleteTemplate: '/review-service/api/external/reviewTemplates/{id}',
  createTemplatePart: '/review-service/api/external/reviewTemplateParts',
  updateTemplatePart: '/review-service/api/external/reviewTemplateParts/{id}',
  deleteTemplatePart: '/review-service/api/external/reviewTemplateParts/{id}',
  setFeedbackOnReview: '/review-service/api/external/reviews/sellers/feedback',
  getSellerReviewsByItemId: '/review-service/api/external/reviews/items/{id}',
  getItemReview: '/review-service/api/external/reviews/singleItem/{id}',
  createFeedbackByItemId: '/review-service/api/external/reviews/items/{id}/feedback',
  getReviewsBySeller: '/review-service/api/external/reviews/sellers/{id}',
  getByIdForSeller: '/review-service/api/external/reviews/sellers/{id}/uniqueReview',
};
