const api = '/rshb-integration-service/api/external/partners';

interface ProductProperty {
  get: string;
  createDelete: string;
  byId: string;
  sortOrders: string;
}

export const partnersContext = {
  partners: `${api}`,
  sortOrders: `${api}/sortOrders`,
  partnerById: `${api}/{id}`,
  changeStatus: `${api}/{id}/changeStatus`,
  partnerImage: `${api}/{id}/image`,
  characteristics: `${api}/{id}/characteristics`,
  characteristicsCreate: `${api}/characteristics`,
  characteristicById: `${api}/characteristics/{id}`,
  characteristicSortOrder: `${api}/{id}/characteristics/sortOrders`,
  characteristicImage: `${api}/characteristics/{id}/image`,
  products: {
    all: `${api}/{id}/services`,
    sortOrders: `${api}/{id}/services/sortOrders`,
    byId: `${api}/services/{id}`,
    image: `${api}/services/{id}/image`,
    widget: {
      get: `${api}/services/{id}/preview`,
      createDelete: `${api}/services/preview`,
      byId: `${api}/services/preview/{id}`,
      sortOrders: `${api}/services/{id}/preview/sortOrders`,
    } as ProductProperty,
    page: {
      get: `${api}/services/{id}/characteristics`,
      createDelete: `${api}/services/characteristics`,
      sortOrders: `${api}/services/{id}/characteristics/sortOrders`,
      byId: `${api}/services/characteristics/{id}`,
    } as ProductProperty,
  },
  getOptions: `${api}/services/{id}/options`,
  addOptions: `${api}/services/options`,
  deleteOptions: `${api}/services/options`,
  updateOptions: `${api}/services/options`,
};
