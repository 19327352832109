const api = '/item-service/api/external';

export const promotionsContext = {
  all: `${api}/catalog/promotions`,
  promotedSkus: `${api}/promotions/{id}/promotedSkus`,
  appliedSkus: `${api}/promotions/{id}/appliedSkus`,
  sellers: `${api}/catalog/sellerPromotions`,
  sellerTemplate: `${api}/catalog/sellerPromotionsTemplate`,
  create: `${api}/promotions`,
  promotedSkuId: `${api}/promotions/{id}/promotedSkus/{skuId}`,
  appliedSkuId: `${api}/promotions/{id}/promotedSkus/{skuId}`,
  update: `${api}/promotions/{id}`,
  deactivate: `${api}/promotions/{id}/deactivate`,
  publish: `${api}/promotions/{id}/publish`,
  byId: `${api}/catalog/promotions/{id}`,
  operatorTemplate: `${api}/catalog/operatorPromotionsTemplate`,
};
