export const articlesClientContext = {
  getArticles: '/article-service/api/external/articles',
  getArticle: '/article-service/api/external/articles/{id}',
  getActualArticles: '/article-service/api/external/articles/actual',
  deleteArticles: '/article-service/api/external/articles',
  getCategories: '/article-service/api/external/categories',
  updateArticle: '/article-service/api/external/articles/{id}',
  createArticle: '/article-service/api/external/articles',
  updateStatus: '/article-service/api/external/articles/{id}/status',
  updateRelatedArticles: '/article-service/api/external/articles/{id}/related',
  image: '/article-service/api/external/articles/{id}/image',
  region: '/article-service/api/external/articles/region',
};
