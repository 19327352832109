import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BuilderInfoService, CurrencyDisplayType } from '@portal/builder';

@Pipe({
  name: 'portalCurrency',
})
export class PortalCurrencyPipe implements PipeTransform {
  constructor(private readonly builderInfoService: BuilderInfoService) {}
  transform(
    value: number,
    currencyCode: string = this.builderInfoService.currency,
    display: CurrencyDisplayType | string | boolean = this.builderInfoService.settings.currencyDisplayType ||
      'symbol-narrow',
    digitsInfo = '1.0-2',
    locale?: string,
  ): string | null {
    if (!value && value !== 0) {
      return null;
    }

    return new CurrencyPipe(this.builderInfoService.locale)
      .transform(value, currencyCode, display, digitsInfo, locale)
      .replace(/(\d)/, ' $1');
  }
}
