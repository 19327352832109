/**
 * Item service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 *
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ImageContentShared } from "./imageContentShared";
import { SimpleImage } from "./simpleImage";
import { UpdateUploadSkuRequestNoReasonReturnPeriod } from "./updateUploadSkuRequestNoReasonReturnPeriod";

export interface TreeNodeShared {
    id?: number;
    parentId?: number;
    title?: string;
    alias?: string;
    categoryType?: TreeNodeShared.CategoryTypeEnum;
    visible?: boolean;
    verificationRequired?: boolean;
    image?: SimpleImage;
    imageContent?: ImageContentShared;
    children?: Array<TreeNodeShared>;
    count?: number;
    sortOrder?: number;
    metaTitle?: string;
    h1Header?: string;
    description?: string;
    declarationDescription?: string;
    metaDescription?: string;
    metaKeywords?: string;
    itemMetaDescription?: string;
    itemMetaTitle?: string;
    itemMetaKeywords?: string;
    portalDeterminate?: TreeNodeShared.PortalDeterminateEnum;
    percent?: number;
    notLessThan?: number;
    noReasonReturnPeriod?: UpdateUploadSkuRequestNoReasonReturnPeriod;
    warrantyReturnPeriod?: UpdateUploadSkuRequestNoReasonReturnPeriod;
}
export namespace TreeNodeShared {
    export type CategoryTypeEnum = 'ITEMS' | 'SERVICES' | 'FINANCIAL_ITEMS';
    export const CategoryTypeEnum = {
        ITEMS: 'ITEMS' as CategoryTypeEnum,
        SERVICES: 'SERVICES' as CategoryTypeEnum,
        FINANCIALITEMS: 'FINANCIAL_ITEMS' as CategoryTypeEnum
    };
    export type PortalDeterminateEnum = 'COMMON' | 'PENSION' | 'FINANCE';
    export const PortalDeterminateEnum = {
        COMMON: 'COMMON' as PortalDeterminateEnum,
        PENSION: 'PENSION' as PortalDeterminateEnum,
        FINANCE: 'FINANCE' as PortalDeterminateEnum
    };
}
