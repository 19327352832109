import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of , throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, switchMap } from 'rxjs/operators';
import { Responses } from '@portal/core';
import { HTTP_ERROR_HANDLER_OPTIONS } from './error-handler-options';

/**
 * {@link ErrorHandlerInterceptor} catches http error and error status at response and throws errors in order to
 * portal error handler could catch it.
 * If custom error handling is required, `skipErrorHandling` param should be added to request http params.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipErrorHandling = request.params.get('skipErrorHandling');
    const skipErrorHandlingPredicate = request.context.get(HTTP_ERROR_HANDLER_OPTIONS);
    const defaultErrorMessage = request.params.get('defaultErrorMessage');

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) =>
        throwError(() => ({
          ...error,
          skipErrorHandling:
            typeof skipErrorHandlingPredicate?.(error)?.skip === 'function'
              ? skipErrorHandlingPredicate(error)?.skip(error)
              : skipErrorHandling,
          defaultErrorMessage,
        })),
      ),
      switchMap((response: HttpResponse<Responses>) => {
        if (response.type === HttpEventType.Response && response.body?.errorCode) {
          const shouldSkipErrorHandling =
            typeof skipErrorHandlingPredicate?.(response)?.skip === 'function'
              ? skipErrorHandlingPredicate(response)?.skip(response)
              : skipErrorHandling;

          return throwError(() => ({
            ...response,
            skipErrorHandling: shouldSkipErrorHandling,
            defaultErrorMessage,
          }));
        }

        return of(response);
      }),
    );
  }
}
