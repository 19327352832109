/**
 * Content service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ImageSpecification { 
    imageSize: ImageSpecification.ImageSizeEnum;
    extensions: Array<ImageSpecification.ExtensionsEnum>;
}
export namespace ImageSpecification {
    export type ImageSizeEnum = 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL' | 'XXXL' | 'ORIGINAL';
    export const ImageSizeEnum = {
        XS: 'XS' as ImageSizeEnum,
        S: 'S' as ImageSizeEnum,
        M: 'M' as ImageSizeEnum,
        L: 'L' as ImageSizeEnum,
        XL: 'XL' as ImageSizeEnum,
        XXL: 'XXL' as ImageSizeEnum,
        XXXL: 'XXXL' as ImageSizeEnum,
        ORIGINAL: 'ORIGINAL' as ImageSizeEnum
    };
    export type ExtensionsEnum = 'jpg' | 'webp' | 'gif' | 'png';
    export const ExtensionsEnum = {
        Jpg: 'jpg' as ExtensionsEnum,
        Webp: 'webp' as ExtensionsEnum,
        Gif: 'gif' as ExtensionsEnum,
        Png: 'png' as ExtensionsEnum
    };
}