import { InjectionToken } from '@angular/core';
import { RoleSaveRequest } from '@portal/models/roleSaveRequest';
import ProfileTypeEnum = RoleSaveRequest.ProfileTypeEnum;

export const MIN_PASSWORD_LENGTH = 8;
export const TOKEN_KEY = 'token';
export const PRIVILEGES_KEY = 'permissions';
export const PROFILE_TYPE_KEY = 'profileType';

export const AGENT_TYPE = new InjectionToken<ProfileTypeEnum>('PORTAL_AGENT_TYPE');
