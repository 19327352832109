import { Observable } from 'rxjs/internal/Observable';
import { Responses } from '../../interfaces';

export enum NotificationType {
  Error = 'Error',
  Success = 'Success',
}

export interface ActiveNotification {
  type?: NotificationType;
  message?: Observable<string> | string;
  showOnlyMessage?: boolean;
  duration?: number;
  response?: Responses;
}

export interface ActionNotification {
  action?: (r?: Responses) => void;
  message?: Observable<string> | string;
}
