import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PriceValidator {
  static isValidPrice(): ValidatorFn {
    return (c: AbstractControl): Record<string, boolean> | null => {
      const value = /^\d+([.,]\d{1,2})?$/.test(c.value) ? +c.value : NaN;
      if (value !== undefined && isNaN(value)) {
        return { value: true };
      }

      return null;
    };
  }
}
