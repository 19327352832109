import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

export class SelectionToggle<T> {
  get isAllSelected() {
    return this.items$.pipe(map(items => this.selection.selected.length === items.length));
  }
  selection: SelectionModel<T>;

  constructor(private readonly items$: Observable<T[]>, private readonly selected: T[] = []) {
    this.setSelectionModel();
  }

  /**
   * If all items selected then clear all selections otherwise select all items
   */
  masterToggle() {
    return this.isAllSelected.pipe(
      take(1),
      tap(allSelected => {
        allSelected && this.selection.clear();
      }),
      filter(allSelected => !allSelected),
      switchMap(_ => this.items$.pipe(take(1))),
      tap(items => {
        items.forEach(row => {
          this.selection.select(row);
        });
      }),
    );
  }

  private setSelectionModel() {
    this.selection = new SelectionModel<T>(true, this.selected);
  }
}
