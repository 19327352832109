import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'memoize',
})
export class MemoizePipe implements PipeTransform {
  transform(fn: (..._: any[]) => any, ...args: any[]): any {
    return fn(...args);
  }
}
