import { Observable } from 'rxjs/internal/Observable';
import { filter, map, take } from 'rxjs/operators';

export enum ResponseStatus {
  Initial,
  Complete,
  Error,
}

export function requestCompleted<T extends { status: ResponseStatus }>(
  observable$: Observable<T>,
): Observable<boolean> {
  return observable$.pipe(
    filter(req => [ResponseStatus.Complete, ResponseStatus.Error].includes(req.status)),
    map(req => req.status === ResponseStatus.Complete),
    take(1),
  );
}
