import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DefaultSnackBarComponent } from './default-snack-bar.component';

@NgModule({
  declarations: [DefaultSnackBarComponent],
  imports: [CommonModule, TranslateModule, MatIconModule],
  exports: [DefaultSnackBarComponent],
})
export class DefaultSnackBarModule {}
