import { HttpContextToken, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Responses } from '@portal/core';

export interface HttpErrorHandlerPredicate {
  readonly skip?: (error?: HttpErrorResponse | HttpResponse<Responses>) => boolean;
}

export type HttpErrorHandlerPredicateFactory = (error: HttpErrorResponse | HttpResponse<Responses>) => HttpErrorHandlerPredicate;

export const HTTP_ERROR_HANDLER_OPTIONS = new HttpContextToken<HttpErrorHandlerPredicateFactory>(
  () => () => ({})
);

