<div class="d-flex align-items-center" *ngIf="data.type === Type.Success">
  <mat-icon [fontIcon]="'mdi-check'" class="mr-2 success"></mat-icon>
  <div class="text">
    <ng-container *ngIf="!data.showOnlyMessage || !(data.message | async)?.length">
      <span> {{ 'shared.updateSuccess' | translate }}</span>
      <span *ngIf="data.message | async">:</span>
    </ng-container>

    <span *ngIf="data.message"> {{ data.message | async }}</span>
  </div>
</div>

<div class="d-flex align-items-center" *ngIf="data.type === Type.Error">
  <mat-icon [fontIcon]="'mdi-alert-circle'" class="pr-4" color="warn"></mat-icon>
  <div class="text pl-2">
    <span *ngIf="!data.showOnlyMessage || (data.message | async) === undefined">
      {{ 'errors.defaultError' | translate }}
    </span>
    <span *ngIf="!data.showOnlyMessage && data.message">:</span>
    <span *ngIf="data.message"> {{ data.message | async }}</span>
  </div>
</div>
