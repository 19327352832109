import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, take } from 'rxjs/operators';
import { PopupDialogComponent, PopupDialogData, PopupNotificationData } from './popup';

@Injectable({
  providedIn: 'root',
})
export class PopupsService {
  constructor(public dialog: MatDialog) {}

  openConfirmDialogWithCancellation(data: PopupDialogData = {}) {
    data.isConfirm = true;

    return this.openDialogWithData(data);
  }

  openConfirmDialog(data: PopupDialogData = { filter: true }) {
    data.isConfirm = true;
    const dialog = this.openDialogWithData(data).pipe(map(v => !!v));

    return data.filter ? dialog.pipe(filter(v => !!v)) : dialog;
  }

  openNotificationDialog(data: PopupNotificationData) {
    data.text = { ...data.text };

    return this.openDialogWithData(data).pipe(filter(v => !!v));
  }

  private openDialogWithData<T extends PopupDialogData>(data: T) {
    const dialogRef = this.dialog.open<PopupDialogComponent, object, boolean>(PopupDialogComponent, {
      width: '400px',
      data: this.transformStringsToObservable(data),
    });

    return dialogRef.afterClosed().pipe(take(1));
  }

  private transformStringsToObservable<T extends Partial<PopupDialogData | PopupNotificationData>>(data: T) {
    if (!data.text) {
      return data;
    }
    data.text = Object.entries(data.text).reduce(
      (acc, [k, text]) => ((acc[k] = typeof text === 'string' ? of(text) : text), acc),
      {} as Record<string, Observable<string>>,
    );

    return data;
  }
}
