const endpoint = '/maker-checker-service/api/external/checks';

export const makerCheckerClientContext = {
  getAll: `${endpoint}`,
  getById: `${endpoint}/{id}`,
  approve: `${endpoint}/approve`,
  getStatus: `${endpoint}/status`,
  failureReasons: `${endpoint}/failureReasons`,
  bulkAction: `${endpoint}/approve/multiple`,
  entityTypes: `${endpoint}/entityTypes`,
  checksCount: `${endpoint}/onCheck`,
};
