import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import _isNull from 'lodash/isNull';
import _isObjectLike from 'lodash/isObjectLike';

@UntilDestroy()
@Component({
  selector: 'portal-base-control-value-accessor',
  template: '',
})
export abstract class BaseControlValueAccessorComponent implements OnInit, ControlValueAccessor {
  abstract form: UntypedFormGroup | UntypedFormControl;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected constructor() {}

  // eslint-disable-next-line arrow-body-style
  onTouched: () => void = () => {
    return;
  };

  abstract ngOnInit(): void;

  writeValue(val: unknown): void {
    const currentValue = this.form.value;
    const shouldSetEmptyValue = !_isObjectLike(currentValue) && _isNull(val);

    if (val || shouldSetEmptyValue) {
      this.form.patchValue(val, { emitEvent: false });

      return;
    }
  }

  registerOnChange(fn: () => void): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && this.form.enabled) {
      this.form.disable();
    } else if (!isDisabled && this.form.disabled) {
      this.form.enable();
    }
  }

  protected abstract setForm(): void;
}
