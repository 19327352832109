export const settingsContext = {
  deliveryPeriods: '/order-service/rest/api/external/deliveryPeriods',
  deliveryPeriodsTree: '/order-service/rest/api/external/deliveryPeriods/tree',
  deliveryPeriod: '/order-service/rest/api/external/deliveryPeriods/{id}',
  deliveryPeriodMerchant: '/order-service/rest/api/external/deliveryPeriods/merchant',
  relatedItems: {
    upload: `/item-service/api/external/items/related/upload`,
    delete: `/item-service/api/external/items/related/clear`,
    export: `/item-service/api/external/items/related/download`,
    byId: `/item-service/api/external/items/related/{id}`,
  },
  rules: {
    all: `/item-service/api/external/itemRules`,
    byId: `/item-service/api/external/itemRules/{id}`,
  },
  defaultSettings: {
    all: `/item-service/api/external/defaultSettings`,
  },
  metaship: {
    sellerInfo: '/logistics-service/metaship-v2/sellerInfo',
    warehouses: '/logistics-service/metaship-v2/warehouses',
    shops: '/logistics-service/metaship-v2/shops',
  },
  score: '/item-service/api/external/itemSortRatingCoefficients',
  search: {
    synonym: '/search-service/api/external/synonym',
  },
  catalogSettings: '/item-service/api/external/catalogSettings',
  buyerPriceTypes: {
    all: `/user-service/api/external/buyerPriceTypes`,
    byId: `/user-service/api/external/buyerPriceTypes/{id}`,
  },
  seoFiles: {
    all: '/content-service/api/external/seofiles',
    single: '/content-service/api/external/seofiles/name',
  },
};
