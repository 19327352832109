<h3 mat-dialog-title>{{ (data.text?.title | async) || 'popups.confirmation.title' | translate }}</h3>
<mat-dialog-content>
  <span>{{ (data.text?.body | async) || 'popups.confirmation.body' | translate }}</span>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mx-2 mb-2">
  <button class="text-uppercase" mat-button mat-flat-button (click)="cancelClick()">
    {{ (data.text?.cancelButton | async) || 'popups.confirmation.buttons.cancel' | translate }}
  </button>
  <button
    class="text-uppercase"
    color="accent"
    mat-button
    mat-flat-button
    cdkFocusInitial
    *ngIf="data.isConfirm"
    [mat-dialog-close]="true"
  >
    {{ (data.text?.confirmButton | async) || 'popups.confirmation.buttons.confirm' | translate }}
  </button>
</mat-dialog-actions>
<!--<div mat-dialog-actions class="justify-content-end pt-3 pb-3">-->
<!--  -->
<!--</div>-->
