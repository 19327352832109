import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import { OrderResponse } from '@portal/models/orderResponse';
import { BuilderInfoService } from '@portal/builder';
import StatusEnum = OrderResponse.StatusEnum;

@Pipe({
  name: 'orderStatusI18n',
  pure: true,
})
export class OrderStatusI18nPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly builderInfoService: BuilderInfoService,
  ) {}

  transform(value: StatusEnum): Observable<string> {
    const settings = this.builderInfoService.settings;
    const statusMapping: Record<StatusEnum, StatusEnum[]> = settings?.statusesMap;

    let translateValue = null;
    if (Object.values(statusMapping || {}).some((v: StatusEnum[]) => v.includes(value))) {
      translateValue = Object.entries(statusMapping).find(([_, v]) => v.includes(value));
    }
    return this.translateService.get(`orders.status.${translateValue?.[0] || value}`);
  }
}
