import { Observable } from 'rxjs/internal/Observable';

type ObservableText = Observable<string> | string;
interface TextDialogData {
  title: ObservableText;
  body: ObservableText;
  cancelButton?: ObservableText;
}
export interface PopupDialogData {
  text?: Partial<TextDialogData> & { confirmButton?: ObservableText };
  filter?: boolean;
  isConfirm?: boolean;
}
export interface PopupNotificationData {
  text?: TextDialogData;
}
