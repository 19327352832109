const api = '/item-service/api/external';
const categories = `${api}/categories`;

export const financialProductsContext = {
  financialProducts: `${api}/financialItems`,
  financialProduct: `${api}/financialItems/{id}`,
  updateStatus: `${api}/financialItems/{id}/status`,
  productCategory: `${api}/financialItems/{id}/category`,
  image: `${api}/financialItems/{id}/image`,
  properties: `${api}/financialItems/{id}/properties`,
  topRating: `${api}/financialItems/topRating/{id}`,
  sortOrders: `${categories}/sortOrders`,
  regions: `${api}/items/region`,
};
