const api = '/cms-service/api/external';

export const pageBuilderContext = {
  pagesContent: `${api}/pagesContentV2`,
  pageContent: `${api}/pagesContentV2/{id}`,
  pageContentFull: `${api}/pagesContentV2/full`,
  lines: `${api}/lines`,
  linesTemplates: `${api}/lines/templates`,
  lineSizes: `${api}/lines/sizes`,
  line: `${api}/lines/{id}`,
  lineImage: `${api}/lines/{id}/image`,
  blocks: `${api}/blocks`,
  block: `${api}/blocks/{id}`,
  blockTemplates: `${api}/blocks/templates`,
  blockTypes: `${api}/blocks/types`,
  blockImage: `${api}/blocks/{id}/image`,
  elements: `${api}/elements`,
  elementsTemplates: `${api}/elements/templates`,
  element: `${api}/elements/{id}`,
  elementImage: `${api}/elements/{id}/image`,
  templates: `${api}/templates`,
  hints: `${api}/pages/hints`,
};
