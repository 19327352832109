export enum EntityTypes {
  ITEM = 'ITEM',
  ORDER = 'ORDER',
  USER = 'USER',
  COMPANY = 'COMPANY',
  FINANCIAL_ITEM = 'FINANCIAL_ITEM',
  ARTICLE = 'ARTICLE',
}

export enum ServiceByEntityType {
  ITEM = 'item-service',
  ORDER = 'order-service',
  USER = 'user-service',
  COMPANY = 'user-service',
  FINANCIAL_ITEM = 'item-service',
  ARTICLE = 'article-service',
}

export enum JsonSchemaStringFormat {
  DATE = 'date',
  DATE_TIME = 'date-time',
  EMAIL = 'email',
  IPV4 = 'ipv4',
  IPV6 = 'ipv6',
  URI = 'uri',
  REGEX = 'regex',
  JSON_POINTER = 'json-pointer',
}

export enum JsonSchemaType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ARRAY = 'array',
  OBJECT = 'object',
  NULL = 'null',
}

export enum FieldStructureTypes {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ENUM = 'select',
  ARRAY = 'multiSelect',
  STRING_ARRAY = 'stringSet',
  NUMBER_ARRAY = 'numberSet',
  OBJECT = 'object',
}
