import { AbstractControl } from '@angular/forms';

enum NumberErrors {
  lessZero = 'lessZero',
  notInteger = 'notInteger',
}

export class NumberValidators {
  static Errors = NumberErrors;

  static greaterZero(control: AbstractControl) {
    const value = +control?.value;
    if (!isNaN(value) && value > 0) {
      return null;
    }

    return { [NumberValidators.Errors.lessZero]: true };
  }

  static integer(control: AbstractControl) {
    if (Number.isInteger(control?.value)) {
      return null;
    }

    return { [NumberValidators.Errors.notInteger]: true };
  }
}
