import { HttpResponse } from '@angular/common/http';
import { ErrorHandler, NgZone, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { NEVER, of } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { map, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import _get from 'lodash/get';
import { NotificationsService, Responses, stringifyError } from '@portal/core';
import { AuthService } from '../auth.service';
import { ResponseLabel, UncaughtInPromise } from './error-handler.constants';
import { CustomHttpErrorCode, ErrorHandleMap, HttpErrorCode } from './error-handler.interface';

export class PortalErrorHandler implements ErrorHandler {
  error$ = new Subject();

  private defaultErrorMessage: string;
  private skipErrorHandling = false;
  constructor(
    private readonly notificationService: NotificationsService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly ngZone: NgZone,
  ) {
    this.error$
      .asObservable()
      .pipe(
        tap(err => {
          this.defaultErrorMessage = _get(err, 'defaultErrorMessage');
          this.skipErrorHandling = _get(err, 'skipErrorHandling');
        }),
        map(err => this.unwrapUncaughtPromise(err)),
        switchMap(err => this.handleCustomServerError(err)),
        switchMap(err => this.handleServerSideError(err)),
      )
      .subscribe(error => {
        this.handleUnspecifiedError(error);
      });
  }

  handleError(error: any) {
    this.error$.next(error);
  }

  private unwrapUncaughtPromise(error: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    return error.message?.startsWith(UncaughtInPromise) ? error.rejection : error;
  }

  private handleUnspecifiedError(error: any) {
    console.error(error);
  }

  private handleServerSideError(err: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (err?.name === ResponseLabel.HttpErrorResponse) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.handleHttpErrorByStatus(err.status);

      return NEVER;
    }

    return of(err);
  }

  private handleCustomServerError(err: HttpResponse<Responses>) {
    if (this.isCustomServerError(err)) {
      const code = err.body.errorCode as any;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.handleHttpErrorByStatus(+CustomHttpErrorCode[code], stringifyError(err.body) || err.body.message);

      return NEVER;
    }

    return of(err);
  }

  private isCustomServerError(error: HttpResponse<Responses>): boolean {
    return !!error.body?.errorCode;
  }

  private handleHttpErrorByStatus(status?: HttpErrorCode, message = '') {
    const actionMap: ErrorHandleMap = {
      [HttpErrorCode.Forbidden]: () => {
        this.showCustomMessage('errors.forbidden');
      },
      [HttpErrorCode.NotFound]: () => {
        this.navigateToNotFound();
      },
      [HttpErrorCode.ServiceUnavailable]: () => {
        this.showCustomMessage('errors.serviceUnavailable');
      },
      [HttpErrorCode.Unauthorized]: () => {
        // do nothing in favour of RefreshTokenInterceptor
      },
      [HttpErrorCode.InternalServerError]: () => {
        this.showCustomMessage('errors.internalServerError');
      },
      [HttpErrorCode.BadRequest]: () => {
        this.showCustomMessage(message);
      },
      [HttpErrorCode.TimeOut]: () => {
        this.showCustomMessage(message);
      },
      [HttpErrorCode.Default]: () => {
        !this.skipErrorHandling && this.showCustomMessage(this.defaultErrorMessage || message, false);
      },
    };

    actionMap[status] ? actionMap[status]() : actionMap[HttpErrorCode.Default]();
  }

  private showCustomMessage(message: string, showOnlyMessage = true) {
    this.ngZone.run(() => {
      this.notificationService.showErrorMessage({
        message: this.translateService.get(message || 'errors.defaultError'),
        showOnlyMessage: !message ? true : showOnlyMessage,
      });
    });
  }

  private logout() {
    this.ngZone.run(() => {
      this.authService.logOut();
    });
  }

  private navigateToNotFound() {
    this.ngZone.run(() => {
      this.router.navigate(['/not-found']);
    });
  }
}

export const ErrorHandlerProvider: Provider = {
  provide: ErrorHandler,
  useClass: PortalErrorHandler,
  deps: [NotificationsService, TranslateService, Router, AuthService, NgZone],
};
