import { HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { NotificationsService, RequestLoaderService } from '@portal/core';
import { ContentHeaders } from './download.service.interface';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  requestLoaderService: RequestLoaderService;
  notificationsService: NotificationsService;

  constructor() {
    this.requestLoaderService = inject(RequestLoaderService);
    this.notificationsService = inject(NotificationsService);
  }

  downloadFile(resp: HttpResponse<Blob>, name: string) {
    const type = resp.headers.get(ContentHeaders.ContentType);
    const contentDispositionHeader = resp.headers.get(ContentHeaders.ContentDisposition);
    if (!type || !contentDispositionHeader) {
      return;
    }

    const fns = contentDispositionHeader.split('filename=');
    const fn = (fns.length && fns[1]) || name;
    const blob = new File([resp.body], fn, { type });

    this.generateLinkAndDownload(blob, fn);
  }

  generateLinkAndDownload(blob: Blob, fileName: string) {
    const anchor = this.createTagElement(fileName);

    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onload = () => {
      anchor.href = reader.result as string;
      anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
      document.body.appendChild(anchor); // important in Firefox
      anchor.click();

      this.requestLoaderService.show();
    };

    reader.onloadend = () => {
      anchor.remove();
      this.requestLoaderService.hide();
    };
  }

  downloadImageWithXhr(url: string, name: string) {
    this.requestLoaderService.show();
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = () => {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(xhr.response);
      const tag = this.createTagElement(name);
      tag.href = imageUrl;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      window.URL.revokeObjectURL(imageUrl);
      this.notificationsService.showSuccessMessage();
    };

    xhr.onerror = () => {
      this.notificationsService.showErrorMessage();
      this.requestLoaderService.hide();
    };

    xhr.onloadend = () => {
      this.requestLoaderService.hide();
    };
    xhr.send();
  }

  private createTagElement(name: string): HTMLAnchorElement {
    const tag = document.createElement('a');
    tag.download = name;

    return tag;
  }
}

// TODO:Replace usage of this function with downloadService.generateLinkAndDownload
export function generateLinkAndDownload(blob: Blob, fileName: string): void {
  generateLink(window.URL.createObjectURL(blob), (anchor: HTMLAnchorElement) => {
    anchor.target = '_blank';
    anchor.download = fileName;
    anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
  });
}

export function generateLinkAndClick(url: string): void {
  generateLink(url, (anchor: HTMLAnchorElement) => {
    anchor.target = '_blank';
  });
}

export function generateIframeAndPrint(blob: Blob): void {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = window.URL.createObjectURL(blob);
  document.body.appendChild(iframe);
  const iframeContentWindow = iframe.contentWindow;
  iframe.onload = () => {
    iframeContentWindow.focus();
    iframeContentWindow.print();
  };
  iframeContentWindow.onafterprint = () => document.body.removeChild(iframe);
}

function generateLink(url: string, mutateFn: (anchor: HTMLAnchorElement) => void): void {
  const anchor = document.createElement('a');
  anchor.href = url;
  mutateFn(anchor);
  document.body.appendChild(anchor); // important in Firefox
  anchor.click();
  anchor.remove();
}

export async function convertBase64ToBlob(base64: string, type: string = 'application/pdf') {
  const res = await fetch(`data:${type};base64,${base64}`);

  return res.blob();
}

export function isResponseValidAvailableForDownload(response: HttpResponse<Blob>): boolean {
  return (
    !!response.headers.get(ContentHeaders.ContentType) && !!response.headers.get(ContentHeaders.ContentDisposition)
  );
}
