/**
 * Order service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BuyerContact } from './buyerContact';
import { CancelReason } from './cancelReason';
import { DigitalArtefactInfo } from './digitalArtefactInfo';
import { JsonNode } from './jsonNode';
import { OrderDeliveryShared } from './orderDeliveryShared';
import { OrderFlowShared } from './orderFlowShared';
import { OrderItemResponse } from './orderItemResponse';
import { OrderModificationResponse } from './orderModificationResponse';
import { OrderPaymentPlanShared } from './orderPaymentPlanShared';
import { OrderPromocode } from './orderPromocode';
import { OrderSystemCommentShared } from './orderSystemCommentShared';
import { PaymentRefundResponse } from './paymentRefundResponse';
import { PaymentShared } from './paymentShared';
import { SlaPerformance } from './slaPerformance';

export interface OrderResponse { 
    id?: number;
    unitedOrderId?: number;
    reserveId?: number;
    currency?: OrderResponse.CurrencyEnum;
    digitalArtefactInfos?: Array<DigitalArtefactInfo>;
    sellerId?: number;
    legalSeller?: boolean;
    buyerId?: number;
    cancelReason?: CancelReason;
    cancelExtendedComment?: string;
    disputeId?: number;
    reviewId?: number;
    slaPerformance?: SlaPerformance;
    buyerType?: OrderResponse.BuyerTypeEnum;
    buyerRating?: number;
    contactPerson?: BuyerContact;
    status?: OrderResponse.StatusEnum;
    terminated?: boolean;
    terminatedAt?: Date;
    statusUpdatedAt?: Date;
    paymentPlan?: OrderPaymentPlanShared;
    payments?: Array<PaymentShared>;
    orderActions?: Array<OrderResponse.OrderActionsEnum>;
    createdAt?: Date;
    updatedAt?: Date;
    totalSumWithoutDiscount?: number;
    totalSum?: number;
    initialTotalSum?: number;
    totalDiscount?: number;
    deliveryPrice?: number;
    negotiationSum?: number;
    totalVat?: number;
    sellerVatCoefficient?: number;
    deliveryMethod?: OrderDeliveryShared;
    orderFlows?: Array<OrderFlowShared>;
    paymentRefunds?: Array<PaymentRefundResponse>;
    orderModifications?: Array<OrderModificationResponse>;
    paymentMethod?: OrderResponse.PaymentMethodEnum;
    part?: number;
    items?: Array<OrderItemResponse>;
    buyerComment?: string;
    sellerComment?: string;
    operatorComment?: string;
    systemComment?: OrderSystemCommentShared;
    additionalFields?: { [key: string]: JsonNode; };
    promocode?: OrderPromocode;
    isChallan?: boolean;
    type?: OrderResponse.TypeEnum;
}
export namespace OrderResponse {
    export type CurrencyEnum = 'RUB' | 'USD' | 'GEL' | 'KZT' | 'AZN' | 'INR' | 'SAR' | 'TMT' | 'XOF' | 'ZAR' | 'CHF' | 'NGN' | 'EGP';
    export const CurrencyEnum = {
        RUB: 'RUB' as CurrencyEnum,
        USD: 'USD' as CurrencyEnum,
        GEL: 'GEL' as CurrencyEnum,
        KZT: 'KZT' as CurrencyEnum,
        AZN: 'AZN' as CurrencyEnum,
        INR: 'INR' as CurrencyEnum,
        SAR: 'SAR' as CurrencyEnum,
        TMT: 'TMT' as CurrencyEnum,
        XOF: 'XOF' as CurrencyEnum,
        ZAR: 'ZAR' as CurrencyEnum,
        CHF: 'CHF' as CurrencyEnum,
        NGN: 'NGN' as CurrencyEnum,
        EGP: 'EGP' as CurrencyEnum
    };
    export type BuyerTypeEnum = 'COMMON_BUYER' | 'COMMON_SELLER' | 'LEGAL_BUYER' | 'LEGAL_SELLER' | 'OPERATOR' | 'COMMON_AGENT' | 'LEGAL_AGENT' | 'UNKNOWN' | 'MODERATOR' | 'TECHNICAL_USER' | 'COURIER';
    export const BuyerTypeEnum = {
        COMMONBUYER: 'COMMON_BUYER' as BuyerTypeEnum,
        COMMONSELLER: 'COMMON_SELLER' as BuyerTypeEnum,
        LEGALBUYER: 'LEGAL_BUYER' as BuyerTypeEnum,
        LEGALSELLER: 'LEGAL_SELLER' as BuyerTypeEnum,
        OPERATOR: 'OPERATOR' as BuyerTypeEnum,
        COMMONAGENT: 'COMMON_AGENT' as BuyerTypeEnum,
        LEGALAGENT: 'LEGAL_AGENT' as BuyerTypeEnum,
        UNKNOWN: 'UNKNOWN' as BuyerTypeEnum,
        MODERATOR: 'MODERATOR' as BuyerTypeEnum,
        TECHNICALUSER: 'TECHNICAL_USER' as BuyerTypeEnum,
        COURIER: 'COURIER' as BuyerTypeEnum
    };
    export type StatusEnum = 'CREATED' | 'AWAITING_CONFIRMATION' | 'AWAITING_PAYMENT' | 'CONFIRMED_PAYMENT' | 'PARTIALLY_PAID' | 'SHIPPED' | 'DELIVERED' | 'ISSUED' | 'CANCELED' | 'CANCELED_BY_SELLER' | 'CANCELED_BY_OPERATOR' | 'AWAITING_ASSEMBLY' | 'ASSEMBLY' | 'ASSEMBLED' | 'WAITING_DELIVERY' | 'RETURNED' | 'TIMEOUT' | 'BUY_IN_CREDIT';
    export const StatusEnum = {
        CREATED: 'CREATED' as StatusEnum,
        AWAITINGCONFIRMATION: 'AWAITING_CONFIRMATION' as StatusEnum,
        AWAITINGPAYMENT: 'AWAITING_PAYMENT' as StatusEnum,
        CONFIRMEDPAYMENT: 'CONFIRMED_PAYMENT' as StatusEnum,
        PARTIALLYPAID: 'PARTIALLY_PAID' as StatusEnum,
        SHIPPED: 'SHIPPED' as StatusEnum,
        DELIVERED: 'DELIVERED' as StatusEnum,
        ISSUED: 'ISSUED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum,
        CANCELEDBYSELLER: 'CANCELED_BY_SELLER' as StatusEnum,
        CANCELEDBYOPERATOR: 'CANCELED_BY_OPERATOR' as StatusEnum,
        AWAITINGASSEMBLY: 'AWAITING_ASSEMBLY' as StatusEnum,
        ASSEMBLY: 'ASSEMBLY' as StatusEnum,
        ASSEMBLED: 'ASSEMBLED' as StatusEnum,
        WAITINGDELIVERY: 'WAITING_DELIVERY' as StatusEnum,
        RETURNED: 'RETURNED' as StatusEnum,
        TIMEOUT: 'TIMEOUT' as StatusEnum,
        BUYINCREDIT: 'BUY_IN_CREDIT' as StatusEnum
    };
    export type OrderActionsEnum = 'ONLINE_PAYMENT' | 'CANCELLATION_BY_BUYER' | 'CANCELLATION_BY_SELLER' | 'CANCELLATION_BY_OPERATOR' | 'REPEAT_ORDER' | 'CREATE_REVIEW' | 'RETURN_ITEM' | 'MARK_AS_DELIVERED' | 'CHANGE_SKU' | 'MARK_AS_REFUND' | 'CHANGE_DELIVERY_PERIOD' | 'CHANGE_TOTAL' | 'RETRY_DELIVERY_CREATE' | 'DISPUTE_CREATE' | 'MARK_IN_ASSEMBLY' | 'MARK_AS_ASSEMBLED' | 'READY_FOR_DELIVERY' | 'UNDER_CONSIDERATION' | 'APPROVE_INTERVIEW' | 'GET_CANDIDATE' | 'APPROVE_AGREEMENT' | 'CANDIDATE_IN_RENT' | 'END_RENT' | 'CLOSE_ORDER_RENT';
    export const OrderActionsEnum = {
        ONLINEPAYMENT: 'ONLINE_PAYMENT' as OrderActionsEnum,
        CANCELLATIONBYBUYER: 'CANCELLATION_BY_BUYER' as OrderActionsEnum,
        CANCELLATIONBYSELLER: 'CANCELLATION_BY_SELLER' as OrderActionsEnum,
        CANCELLATIONBYOPERATOR: 'CANCELLATION_BY_OPERATOR' as OrderActionsEnum,
        REPEATORDER: 'REPEAT_ORDER' as OrderActionsEnum,
        CREATEREVIEW: 'CREATE_REVIEW' as OrderActionsEnum,
        RETURNITEM: 'RETURN_ITEM' as OrderActionsEnum,
        MARKASDELIVERED: 'MARK_AS_DELIVERED' as OrderActionsEnum,
        CHANGESKU: 'CHANGE_SKU' as OrderActionsEnum,
        MARKASREFUND: 'MARK_AS_REFUND' as OrderActionsEnum,
        CHANGEDELIVERYPERIOD: 'CHANGE_DELIVERY_PERIOD' as OrderActionsEnum,
        CHANGETOTAL: 'CHANGE_TOTAL' as OrderActionsEnum,
        RETRYDELIVERYCREATE: 'RETRY_DELIVERY_CREATE' as OrderActionsEnum,
        DISPUTECREATE: 'DISPUTE_CREATE' as OrderActionsEnum,
        MARKINASSEMBLY: 'MARK_IN_ASSEMBLY' as OrderActionsEnum,
        MARKASASSEMBLED: 'MARK_AS_ASSEMBLED' as OrderActionsEnum,
        READYFORDELIVERY: 'READY_FOR_DELIVERY' as OrderActionsEnum,
        UNDERCONSIDERATION: 'UNDER_CONSIDERATION' as OrderActionsEnum,
        APPROVEINTERVIEW: 'APPROVE_INTERVIEW' as OrderActionsEnum,
        GETCANDIDATE: 'GET_CANDIDATE' as OrderActionsEnum,
        APPROVEAGREEMENT: 'APPROVE_AGREEMENT' as OrderActionsEnum,
        CANDIDATEINRENT: 'CANDIDATE_IN_RENT' as OrderActionsEnum,
        ENDRENT: 'END_RENT' as OrderActionsEnum,
        CLOSEORDERRENT: 'CLOSE_ORDER_RENT' as OrderActionsEnum
    };
    export type PaymentMethodEnum = 'ONLINE' | 'CASH' | 'ADVANCED' | 'EWALLET' | 'CARDS' | 'CREDIT' | 'FACTORING';
    export const PaymentMethodEnum = {
        ONLINE: 'ONLINE' as PaymentMethodEnum,
        CASH: 'CASH' as PaymentMethodEnum,
        ADVANCED: 'ADVANCED' as PaymentMethodEnum,
        EWALLET: 'EWALLET' as PaymentMethodEnum,
        CARDS: 'CARDS' as PaymentMethodEnum,
        CREDIT: 'CREDIT' as PaymentMethodEnum,
        FACTORING: 'FACTORING' as PaymentMethodEnum
    };
    export type TypeEnum = 'PHYSICAL' | 'DIGITAL' | 'TENDER' | 'INTERVIEW';
    export const TypeEnum = {
        PHYSICAL: 'PHYSICAL' as TypeEnum,
        DIGITAL: 'DIGITAL' as TypeEnum,
        TENDER: 'TENDER' as TypeEnum,
        INTERVIEW: 'INTERVIEW' as TypeEnum
    };
}