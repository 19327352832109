const apiOrder = '/order-service/rest/api/external';
const apiItem = '/item-service/api/external';
const apiLogistics = '/logistics-service/api/external';

export const paymentDeliveryContext = {
  getPayments: `${apiOrder}/merchantPaymentMethods`,
  setPayments: `${apiOrder}/merchantPaymentMethods`,
  getDeliveries: `${apiLogistics}/sellerDeliveryMethods`,
  setDeliveries: `${apiLogistics}/sellerDeliveryMethods`,
  item: {
    getPayments: `${apiItem}/items/{id}/paymentMethods`,
    setPayments: `${apiItem}/items/{id}/paymentMethods`,
    getDeliveries: `${apiLogistics}/itemDeliveryMethods`,
    setDeliveries: `${apiLogistics}/itemDeliveryMethods`,
  },
};
