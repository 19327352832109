import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({ providedIn: 'root' })
export class RequestLoaderService {
  isLoading = false;
  loaderState = new Subject<{ isLoading: boolean; lockScreen?: boolean }>();
  requestCounter = 0;
  show(lockScreen = false) {
    if (!this.requestCounter && !this.isLoading) {
      this.isLoading = true;
      this.loaderState.next({ isLoading: this.isLoading, lockScreen });
    }
    this.requestCounter++;
  }

  hide() {
    this.requestCounter--;
    if (!this.requestCounter && this.isLoading) {
      this.isLoading = false;
      this.loaderState.next({ isLoading: this.isLoading });
    }
  }
}
