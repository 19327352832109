import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PromocodePaginationRequest } from '@portal/models/promocodePaginationRequest';
import LangEnum = PromocodePaginationRequest.LangEnum;

@Injectable({
  providedIn: 'root',
})
export class LangService {
  get currentLang(): LangEnum | string {
    return this.translateService && this.translateService.currentLang
      ? this.translateService.currentLang.toUpperCase()
      : LangEnum.EN;
  }

  constructor(private readonly translateService: TranslateService) {}
}
