import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes';
import { HintComponent } from './hint/hint.component';
import { HintsDirective } from './hints.directive';

@NgModule({
  imports: [TranslateModule, MatIconModule, PipesModule],
  declarations: [HintComponent, HintsDirective],
  exports: [HintComponent, HintsDirective],
  providers: []
})
export class HintsModule {}
