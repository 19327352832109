import { HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Validators } from '@angular/forms';
import _isArray from 'lodash/isArray';
import _isObjectLike from 'lodash/isObjectLike';
import _moment, { MomentSetObject } from 'moment';
import { CharacteristicWrapperShared } from '@portal/models/characteristicWrapperShared';
import { RgbaColour } from '@portal/models/rgbaColour';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { NUMBER_LENGTH } from '../constants/utils.constants';
import { ContentTypeEnum, Times } from '../enums';
import { Responses } from '../interfaces';
import { Option } from '../interfaces/form.interfaces';
import { cleanEmptyValues } from './data-cleaning.helpers';

export const replaceId = (url: string, id: number | string) => url.replace('{id}', id.toString());

export const isObjectNotArray = (value: object): boolean => _isObjectLike(value) && !_isArray(value);

export const getHttpParams = (params: object, encoder?: HttpParameterCodec): HttpParams => {
  const adjustedParams = adjustHttpParams(params);

  return new HttpParams({
    fromObject: adjustedParams,
    ...(encoder ? { encoder } : {}),
  });
};

export function adjustHttpParams(obj: object): Record<string, string> {
  const keys = Object.keys(obj);
  if (keys.includes('page.size')) {
    obj['page.size'] = obj['page.size'] || DEFAULT_PAGE_SIZE;
  }
  const cleanedObject = cleanEmptyValues(obj);

  return Object.entries(cleanedObject).reduce((acc, [k, v]) => {
    if (isObjectNotArray(v)) {
      const strObj = flattenObject(k, v);

      return {
        ...acc,
        ...strObj,
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    acc[k] = v.toString();

    return acc;
  }, {} as Record<string, string>);
}

function flattenObject(initialKey: string, objValue: object): object {
  return Object.entries(objValue).reduce((acc, [key, value]) => {
    let updatedValue = isObjectNotArray(value) ? flattenObject(key, value) : value;
    if (isObjectNotArray(updatedValue)) {
      updatedValue = flattenObject(initialKey, updatedValue);
    }
    const isUpdatedValueObject = isObjectNotArray(updatedValue);

    const realKey = `${initialKey}.${key}`;
    if (!isUpdatedValueObject) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      acc[realKey] = value.toString();
    }

    return isUpdatedValueObject ? { ...acc, ...updatedValue } : acc;
  }, {} as Record<string, string>);
}

export const getJSONContentType = () => ({ 'Content-type': 'application/json' });

export const numberCombinationValidator = (minLength = NUMBER_LENGTH, maxLength = NUMBER_LENGTH) => [
  Validators.minLength(minLength),
  Validators.maxLength(maxLength),
  Validators.pattern(/^[0-9]+$/),
];

// TODO get rid of all usages cause it receives all characteristics and not packageType  which leads to misuse
export const getPackageType = (characteristicWrapper: CharacteristicWrapperShared) =>
  characteristicWrapper.enumCharacteristics.map(c => c.options.map(o => `${o.title}; `));

export function getISODate(date: Date | string, format: string = _moment.HTML5_FMT.DATE): string {
  date = ifStringConvertToDate(date);

  return _moment(date).format(format);
}

export function getISOLocalDate(date: Date | string, format: string = _moment.HTML5_FMT.DATETIME_LOCAL): string {
  return getISODate(date, format);
}

export function getISOOffsetDate(date: Date | string): Date {
  return _moment(date).format() as unknown as Date;
}

/**
 * @param filters contains all parameters for the request
 * @param properties defines date keys
 * @param dayPeriods is used if it is required to return dates within the range
 * from the beginning( 00:00:00) of the day to the end of the exact day(23:59:59).
 * Usually is used in the filters the BE requires localDateTime.
 */
export function convertDatesInRequest<T, K extends keyof T>(
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  filters: Partial<Record<K, string | Date | any>>,
  properties: K[],
  dayPeriods?: DayPeriodEnum[],
) {
  const dateWithTime = (k: K, index: number) =>
    getISOLocalDate(
      _moment(filters[k]).set(DAY_PERIODS[dayPeriods[index]]).toDate(),
      _moment.HTML5_FMT.DATETIME_LOCAL_MS,
    );

  properties.forEach((p, index) => {
    if (filters[p]) {
      filters[p] = dayPeriods ? dateWithTime(p, index) : getISOLocalDate(filters[p] as string);
    } else {
      filters[p] = null;
    }
  });

  return filters;
}

export function convertToOffsetDates<T, K extends keyof T>(
  filters: Partial<Record<K, string | Date | object>>,
  properties: K[],
  dayPeriods?: DayPeriodEnum[],
): Partial<Record<K, string | Date | object>> {
  const dateWithTime = (k: K, index: number) =>
    getISOOffsetDate(_moment(filters[k]).set(DAY_PERIODS[dayPeriods[index]]).toDate());

  properties.forEach((p, index) => {
    if (filters[p]) {
      filters[p] = dayPeriods ? dateWithTime(p, index) : getISOOffsetDate(filters[p] as string);
    } else {
      filters[p] = null;
    }
  });

  return filters;
}

export enum DayPeriodEnum {
  Start,
  End,
}

type DayPeriodSetObject = { [k in DayPeriodEnum]: MomentSetObject };
const DAY_PERIODS: DayPeriodSetObject = {
  [DayPeriodEnum.Start]: { hour: 0, minute: 0, second: 0 },
  [DayPeriodEnum.End]: { hour: 23, minute: 59, second: 59 },
};

export function ifStringConvertToDate(date: string | Date): Date {
  return typeof date === 'string' ? new Date(date) : date;
}

export function getAsTwoDigits(value: number) {
  const strValue = value.toString();

  return strValue.length === 1 ? `0${strValue}` : strValue;
}

export function getOptionsFromEnum(enumObj: object): Option[] {
  return Object.keys(enumObj).map(key => ({ value: key, name: key }));
}

export function getMultipartFormData(
  params: { name: string; value: string | Blob | object; contentType?: ContentTypeEnum }[],
): FormData {
  const payload = new FormData();

  return params.reduce((acc, c) => {
    if (c.value instanceof Blob) {
      acc.append(c.name, c.value);

      return acc;
    }
    acc.append(c.name, new Blob([JSON.stringify(c.value)], { type: c.contentType || ContentTypeEnum.ApplicationJSON }));

    return acc;
  }, payload);
}

export function stringifyError(err: Responses): string {
  if (!err.errorValue) {
    return '';
  }

  return Object.entries(err.errorValue).reduce((acc, [k, v]: [string, string]) => ((acc += `${k}: ${v}`), acc), '');
}

export function getRemovedAddedValues<T extends number | string>(current: T[], changed: T[]): T[][] {
  const removed = current.filter(i => !changed.some(c => c === i));
  const added = changed.filter(c => !current.some(i => i === c));

  return [removed, added];
}

export const convertStringToRgba = (value: string): RgbaColour => {
  const parsedColor = value
    .replace(/[^\d,.]/g, '')
    .split(',')
    .map(v => +v);

  return {
    red: parsedColor[Times.Zero],
    green: parsedColor[Times.One],
    blue: parsedColor[Times.Two],
    alpha: parsedColor[Times.Three],
  };
};

export function uuid(): string {
  return crypto.getRandomValues(new Uint32Array(4)).reduce((res, item) => res + item.toString(16), '');
}
