import { buyerClientContext, companiesClientContext, dictionaryContext, filterContext } from '@portal/api-endpoints';

export const clientContext = {
  problems: `${buyerClientContext.problems}`,
  filter: {
    itemById: `${filterContext.itemById}`,
  },
  dictionary: `${dictionaryContext.dictionary}`,
  companies: {
    uploadImage: `${companiesClientContext.image.uploadImage}`,
    updateImage: `${companiesClientContext.image.updateImage}`,
    reorganizeImage: `${companiesClientContext.image.reorganizeImage}`,
    deleteImage: `${companiesClientContext.image.deleteImage}`,
  },
};
