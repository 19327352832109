import { AbstractControl, ValidationErrors } from '@angular/forms';

export class StringValidators {
  static requiredWithoutSpaces(control: AbstractControl): ValidationErrors {
    const value = control?.value as string;
    if (!value || typeof value !== 'string' || !value.trim()) {
      return { requiredWithoutSpaces: true };
    }

    return null;
  }

  static validUrl(control: AbstractControl): ValidationErrors {
    const value = control?.value as string;
    if (!value || typeof value !== 'string') {
      return null;
    }
    try {
      new URL(value);
    } catch (error) {
      return { validUrl: true };
    }

    return null;
  }
}
