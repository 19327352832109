const api = '/item-service/api/external/sourcing';

export const sourcingContext = {
  inputs: `${api}/inputs`,
  input: `${api}/inputs/{id}`,
  farmers: `${api}/inputs/farmers`,
  outputs: `${api}/outputs`,
  output: `${api}/outputs/{id}`,
  outputFarmers: `${api}/outputs/farmers`,
};
