import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textCut',
  pure: true,
})
export class TextCutPipe implements PipeTransform {
  transform(value: string, maxLength?: number): any {
    if (!value) {
      return null;
    }
    if (!maxLength) {
      return value;
    }

    return value.length > maxLength ? `${value.slice(0, maxLength)}...` : value;
  }
}
