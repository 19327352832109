import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';
import _find from 'lodash/find';

@Injectable()
export class StoreService {
  private readonly sourcesData: any[] = [];
  private readonly sourcesEvents: any[] = [];

  data(name: string) {
    return {
      get: () => this.getData(name),
      set: (data?: any) => {
        this.setData(name, data);
      },
    };
  }

  event(name: string) {
    return {
      get: () => this.getEvent(name),
      emit: (data?: any) => {
        this.dispatchEvent(name, data);
      },
    };
  }

  private getData(name: string) {
    const source = this.findSourceDataByName(name);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return source.obs;
  }

  private setData(name: string, data?: any): void {
    const source = this.findSourceDataByName(name);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    source.source.next(data);
  }

  private getEvent(name: string) {
    const source = this.findSourceEventByName(name);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return source.obs;
  }

  private dispatchEvent(name: string, data?: any): void {
    const source = this.findSourceEventByName(name);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    source.source.next(data);
  }

  private findSourceDataByName(name: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    let source = _find(this.sourcesData, sourceItem => sourceItem.name === name);

    if (!source) {
      source = {
        name,
        source: new BehaviorSubject<any>(null),
      };

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
      source.obs = source.source.asObservable();
      this.sourcesData.push(source);
    }

    return source;
  }

  private findSourceEventByName(name: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    let source = _find(this.sourcesEvents, sourceItem => sourceItem.name === name);

    if (!source) {
      source = {
        name,
        source: new Subject<any>(),
      };

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
      source.obs = source.source.asObservable();
      this.sourcesEvents.push(source);
    }

    return source;
  }
}
