export const filtersContext = {
  sets: '/item-service/api/external/items/related/set',
  set: '/item-service/api/external/items/related/set/{id}',
  setLine: '/item-service/api/external/items/related/set/{id}/line',
  lines: '/item-service/api/external/items/related/lines',
  line: '/item-service/api/external/items/related/lines/{id}',
  filter: '/item-service/api/external/items/related/lines/{id}/filter',
  storedFilters: '/item-service/api/external/items/related/storedFilters',
  storedFilter: `/item-service/api/external/items/related/storedFilters/{id}`,
  loadFilters: '/filter-service/api/external/filter',
  manualItems: '/item-service/api/external/items/related',
  manualItem: '/item-service/api/external/items/related/{id}',
};
