import { Pipe, PipeTransform } from '@angular/core';
import { BuilderInfoService } from '@portal/builder';

@Pipe({
  name: 'multilang',
  pure: true,
})
export class MultiLangPipe implements PipeTransform {
  constructor(private readonly builderInfoService: BuilderInfoService) {}
  transform(value: unknown): string {
    return value ? (value as Record<string, string>)[this.builderInfoService.lang] : '';
  }
}
