import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'portal-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintComponent {
  @HostBinding('class.breadcrumb')
  breadcrumb: boolean;

  @HostBinding('class.title')
  title: boolean;

  @Input() link: string;
}
