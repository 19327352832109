import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActionResultBoolean } from '@portal/models/actionResultBoolean';
import { ActionResultEnumMapProfileTypeListRoleShared } from '@portal/models/actionResultEnumMapProfileTypeListRoleShared';
import { ActionResultLegalSellerMainInfoShared } from '@portal/models/actionResultLegalSellerMainInfoShared';
import { ActionResultLegalSellerShared } from '@portal/models/actionResultLegalSellerShared';
import { ActionResultListCompanyRegionShared } from '@portal/models/actionResultListCompanyRegionShared';
import { ActionResultListOrganizationTypeShared } from '@portal/models/actionResultListOrganizationTypeShared';
import { ActionResultOperatorShared } from '@portal/models/actionResultOperatorShared';
import { ActionResultString } from '@portal/models/actionResultString';
import { CompanyFullUpdateRequest } from '@portal/models/companyFullUpdateRequest';
import { CompanyRegionShared } from '@portal/models/companyRegionShared';
import { CompanyShared } from '@portal/models/companyShared';
import { LegalSellerMainInfoShared } from '@portal/models/legalSellerMainInfoShared';
import { LegalSellerShared } from '@portal/models/legalSellerShared';
import { LegalSellerUpdateRequest } from '@portal/models/legalSellerUpdateRequest';
import { OperatorShared } from '@portal/models/operatorShared';
import { OrganizationTypeShared } from '@portal/models/organizationTypeShared';
import { RoleSaveRequest } from '@portal/models/roleSaveRequest';
import { SaveAdditionalFieldRequest } from '@portal/models/saveAdditionalFieldRequest';
import { SimpleImage } from '@portal/models/simpleImage';
import { UserWithPrivileges } from '../auth.interfaces';
import { AuthService } from '../auth.service';
import { clientContext } from '../client.context';
import ProfileTypeEnum = RoleSaveRequest.ProfileTypeEnum;
import ActionResultMapStringFieldStructureShared = ActionResultMapStringField.ActionResultMapStringFieldStructureShared;
import FieldStructureShared = ActionResultMapStringField.FieldStructureShared;
import EntityTypeEnum = SaveAdditionalFieldRequest.EntityTypeEnum;
import StatusEnum = CompanyShared.StatusEnum;
import { RoleShared } from '@portal/models/roleShared';
import { companiesClientContext } from '@portal/api-endpoints';
import { ActionResultMapStringField } from '@portal/components';
import {
  ContentTypeEnum,
  EnumsTranslation,
  getEnumsTranslation,
  getHttpParams,
  getMultipartFormData,
  replaceId,
} from '@portal/core';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  private readonly avatar = new BehaviorSubject<SimpleImage | string>(undefined);

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
  ) {}

  getAgentShort(): Observable<LegalSellerMainInfoShared> {
    return this.authService.getUserInfo().pipe(
      filter((user: UserWithPrivileges) => user.profileType === ProfileTypeEnum.LEGALSELLER),
      switchMap(() => this.http.get<ActionResultLegalSellerMainInfoShared>(clientContext.agentShort)),
      tap(r => {
        this.setAvatar(r.value.logo);
      }),
      map(r => r.value),
    );
  }

  // #region operator

  // TODO: move to own service
  getAgentById(id: number, includeCompany = true, isLegalSeller = true): Observable<LegalSellerShared> {
    const url = replaceId(isLegalSeller ? clientContext.legalSellerById : clientContext.commonSellerById, id);
    const paramsObj = {
      includeMembers: true,
      includeAdditionalFields: true,
      includeCompany: includeCompany.toString(),
    };
    const params = getHttpParams(paramsObj);

    return this.http.get<ActionResultLegalSellerShared>(url, { params }).pipe(map(r => r.value));
  }

  updateFullCompany(id: number, company: CompanyFullUpdateRequest): Observable<ActionResultString> {
    const url = replaceId(clientContext.updateCompanyById, id);

    return this.http.put<ActionResultString>(url, company);
  }

  updateLegalSellerById(id: number, seller: LegalSellerUpdateRequest): Observable<ActionResultBoolean> {
    const url = replaceId(clientContext.legalSellerById, id);

    return this.http.put<ActionResultBoolean>(url, seller);
  }

  getRoles(types: ProfileTypeEnum[]): Observable<Record<string, RoleShared[]>> {
    const params = types.reduce((acc, t) => acc.append('types', t), getHttpParams({}));

    return this.http
      .get<ActionResultEnumMapProfileTypeListRoleShared>(clientContext.roles, { params })
      .pipe(map(r => r.value));
  }
  // #endregion

  getAgent(profileType: ProfileTypeEnum, sellerId: number): Observable<LegalSellerShared> {
    const url =
      profileType === ProfileTypeEnum.COMMONSELLER
        ? replaceId(clientContext.COMMON_SELLER, sellerId)
        : clientContext.LEGAL_SELLER;

    return this.http
      .get<ActionResultLegalSellerShared>(url, {
        params: getHttpParams({
          includeUser: true,
          includeCompany: true,
          includeContent: true,
          includeAdditionalFields: true,
        }),
      })
      .pipe(map(r => r.value));
  }

  changeSellerStatus(id: number, status: StatusEnum): Observable<ActionResultBoolean> {
    const url = replaceId(companiesClientContext.seller.status, id);

    return this.http.put<ActionResultBoolean>(url, JSON.stringify(status), { headers: getJSONContentType() });
  }

  updateCompany(company: CompanyFullUpdateRequest) {
    return this.http.put<ActionResultBoolean>(clientContext.updateCompany, company);
  }

  updateAgent(agent: LegalSellerUpdateRequest, id: number, isLegalSeller = true): Observable<ActionResultBoolean> {
    return this.http.put<ActionResultBoolean>(
      replaceId(isLegalSeller ? clientContext.legalSellerById : clientContext.commonSellerById, id),
      agent,
    );
  }

  getCompanyRegions(): Observable<CompanyRegionShared[]> {
    return this.http.get<ActionResultListCompanyRegionShared>(clientContext.companyRegions).pipe(map(r => r.value));
  }

  getOperatorInfo(): Observable<OperatorShared> {
    return this.http.get<ActionResultOperatorShared>(clientContext.operatorInfo).pipe(map(r => r.value));
  }

  getOrganizationTypes(): Observable<OrganizationTypeShared[]> {
    return this.http
      .get<ActionResultListOrganizationTypeShared>(clientContext.organizationTypes)
      .pipe(map(r => r.value));
  }

  getTaxOptions() {
    return getEnumsTranslation(this.translateService.get('companies.tax'));
  }

  getLanguageOptions(): Observable<EnumsTranslation> {
    return getEnumsTranslation(this.translateService.get('availableLanguages'));
  }

  getTaxationSystemOptions() {
    return getEnumsTranslation(this.translateService.get('companies.taxationSystem'));
  }

  getCompanyProductTypes() {
    return getEnumsTranslation(this.translateService.get('companies.companyProductTypes'));
  }

  updateAvatar(logo: File): Observable<ActionResultBoolean> {
    return this.http.patch<ActionResultBoolean>(
      clientContext.updateLogo,
      getMultipartFormData([
        { name: 'image', value: logo },
        { name: 'request', value: {} },
      ]),
      { headers: { 'Content-Type': ContentTypeEnum.MultipartForm } },
    );
  }

  getAvatar(): Observable<SimpleImage | string> {
    return this.avatar.asObservable().pipe(distinctUntilChanged());
  }

  setAvatar(image: SimpleImage | string) {
    this.avatar.next(image);
  }

  getFieldsStructure(
    entityType: EntityTypeEnum = EntityTypeEnum.MERCHANT,
  ): Observable<Record<string, FieldStructureShared>> {
    const params = getHttpParams({ entityType });

    return this.http
      .get<ActionResultMapStringFieldStructureShared>(companiesClientContext.fieldsStructure, { params })
      .pipe(map(r => r.value));
  }
}

function getJSONContentType() {
  return { 'Content-type': 'application/json' };
}
