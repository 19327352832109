import { AbstractControl, ValidatorFn } from '@angular/forms';
import _isNumber from 'lodash/isNumber';

export enum ControlValueType {
  // eslint-disable-next-line id-denylist
  Number,
  // eslint-disable-next-line id-denylist
  String,
}

export function forbiddenValueValidator<T>(
  valueBlackList: T[],
  type: ControlValueType = ControlValueType.String,
): ValidatorFn {
  return (c: AbstractControl): Record<string, boolean> | null => {
    if (!c.value) {
      return null;
    }

    const transformedAsNumber = _isNumber(c.value) ? c.value : +(c.value as string)?.trim();
    const realValue: string | number =
      type === ControlValueType.Number ? transformedAsNumber : (c.value as string)?.trim();
    const isInvalid = valueBlackList.includes(realValue as unknown as T);

    return isInvalid ? { forbiddenValue: isInvalid } : null;
  };
}
