const api = '/item-service/api/external/tenders';

export const tendersSkuContext = {
  all: `${api}/sku`,
  getSkusByIds: `${api}/sku/{id}`,
  byId: `${api}/sku/{id}`,
  image: {
    uploadImage: `${api}/sku/{id}/image`,
    updateImage: `${api}/sku/{id}/image/{pictureId}`,
    deleteImage: `${api}/sku/{id}/image/{pictureId}`,
  },
};
