export enum HttpErrorCode {
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  InternalServerError = 500,
  ServiceUnavailable = 503,
  Forbidden = 403,
  TimeOut = 504,
  Default = 0,
}

export enum CustomHttpErrorCode {
  'UNAUTHORIZED' = HttpErrorCode.Unauthorized,
  'SERVICE_UNAVAILABLE' = HttpErrorCode.ServiceUnavailable,
  'INTERNAL_SERVER_ERROR' = HttpErrorCode.InternalServerError,
  'NOT_FOUND' = HttpErrorCode.NotFound,
  'INVALID_TOKEN' = HttpErrorCode.Unauthorized,
  'ACCESS_DENIED' = HttpErrorCode.Forbidden,
}

export type ErrorHandleMap = { [key in HttpErrorCode]: () => void };
