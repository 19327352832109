import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ParamsService<T extends Params = Params> {
  constructor(private readonly activatedRoute: ActivatedRoute, private readonly router: Router) {}

  get urlParams(): T {
    return this.activatedRoute.snapshot.queryParams as T;
  }

  set urlParams(p) {
    this.navigateToParams({ ...(this.urlParams || {}), ...p });
  }

  get urlParamsStream(): Observable<Params> {
    return this.activatedRoute.queryParams;
  }

  setUrlParamsWithReplaceUrl(p: T): void {
    this.navigateToParams({ ...(this.urlParams || {}), ...p }, true);
  }

  /**
   * We have to call in constructor in order to initialize default URL params if they exist,
   * for informing filters component to set values from URL
   * When we have to clean params we send  it with empty values, so we have to send it as a
   * param in order from urlParams setter in order to proceed action and not return;
   */
  navigateToParams(params: T = null, replaceUrl: boolean = false) {
    if (!params || !Object.values(params).some(v => !!v)) {
      return;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge',
      replaceUrl,
    });
  }
}
