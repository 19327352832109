import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export type FormRecord<T, U = unknown> = Record<
  keyof T,
  | (string | boolean | null | number | number[] | Date | ValidatorFn | ValidatorFn[] | U)[]
  | UntypedFormGroup
  | UntypedFormControl
  | UntypedFormArray
>;

export interface Option {
  name: string;
  value: string;
}

export enum FormStatuses {
  DISABLED = 'DISABLED',
  VALID = 'VALID',
  INVALID = 'INVALID',
}
