import { RoleSaveRequest } from '@portal/models/roleSaveRequest';
import { TableQuery } from '@portal/components';
import ProfileTypeEnum = RoleSaveRequest.ProfileTypeEnum;

export type UsersQueryParams = TableQuery<UsersFiltersQuery>;

export interface UsersFiltersQuery {
  email?: string;
  fullName?: string;
  phone?: string;
  profileType?: ProfileTypeEnum;
  excludeCompanyMembers?: boolean;
  ids?: number[];
}

export interface OrdersBack {
  sellerIds: string;
  'page.num': number;
  'page.size': number;
}
