import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { type BuilderInfoService } from '@portal/builder';

@Injectable()
export class TranslationParserService extends TranslateDefaultParser {
  constructor(private readonly builderInfoService: BuilderInfoService) {
    super();
  }

  getValue(target: Record<string, Record<string, any>>, origKey: string): any {
    const i18nMap: Record<string, string> = this.builderInfoService.settings?.extraI18nMap;
    if (!i18nMap) {
      return super.getValue(target, origKey);
    }
    const replaceIdentical = Object.entries(i18nMap ?? {}).find(([key]) => origKey === key)?.[1];
    const replace = replaceIdentical
      ? replaceIdentical
      : Object.entries(i18nMap ?? {}).find(([key]) => origKey.startsWith(key))?.[1];

    if (!replace) {
      return super.getValue(target, origKey);
    }
    let key: string = replace;
    if (replace.endsWith('*')) {
      key = this.replaceAllNestedKeys(origKey, replace.replace('*', ''));
    }
    return super.getValue(target, key);
  }

  private replaceAllNestedKeys(key: string, replaceKey: string): string {
    const orig = key.split('.');
    const nested = replaceKey.split('.');

    return [...nested.slice(0, orig.length), ...orig.slice(nested.length)].join('.');
  }
}
