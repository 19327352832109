import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';

export abstract class BaseDataSource<T, P> implements DataSource<T> {
  protected dataSubject = new BehaviorSubject<T[]>([]);
  protected loadingSubject = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected constructor() {}

  connect(_: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(_: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  getLoadingState() {
    return this.loadingSubject.asObservable();
  }

  abstract load?(q?: P): Subscription;
}
