import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumber } from '@portal/models/phoneNumber';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(phone: PhoneNumber, startMark = '', indented = false): any {
    if (!phone) {
      return '-';
    }

    return `${startMark || ''}${phone?.countryCode}${indented ? ' ' : ''}${phone?.nationalNumber}`;
  }
}
