import { Address } from '@portal/models/address';

export function addressToString(address: Address) {
  if (!address) {
    return '';
  }

  return `${addSeparate(address.countryWithId?.country?.title)}${addSeparate(address?.region.title)}${addSeparate(
    address?.city,
  )}${address?.addressLine || ''}`;
}

function addSeparate(line: string): string {
  return line ? line + ', ' : '';
}
