export const usersClientContext = {
  user: {
    agent: '/user-service/api/external/operators/self',
    changePassword: '/user-service/api/external/operators/self/changePassword',
    roles: '/user-service/api/external/users/roles',
    get: '/user-service/api/external/users',
    updateAgent: '/user-service/api/external/users/self',
    getSelf: '/user-service/api/external/users/self',
  },
  merchantUser: {
    agentShort: '/user-service/api/external/legalSellers/self/mainInfo',
    LEGAL_SELLER: '/user-service/api/external/legalSellers/self',
    COMMON_SELLER: '/user-service/api/external/commonSellers/{id}',
    changePassword: '/user-service/api/external/users/self/changePassword',
    companyRegions: '/user-service/api/external/companyRegions',
    organizationTypes: '/user-service/api/external/organizationTypes',
    updateAgent: '/user-service/api/external/legalSellers/self',
    updateCompany: '/user-service/api/external/companies/self',
    initResetPassword: '/user-service/api/external/passwordResetToken',
    initResetPasswordAnonymous: '/user-service/api/external/passwordResetToken/anonymous',
    initResetPasswordMobile: '/user-service/api/external/recoverPassword/supply',
    completeResetPasswordMobile: '/user-service/api/external/recoverPassword/complete',
    changePasswordWithToken: '/user-service/api/external/users/{id}/changePassword/withToken',
  },
  getUsers: '/user-service/api/external/users',
  createUser: '/user-service/api/external/users',
  getUser: '/user-service/api/external/users/{id}',
  updateUser: '/user-service/api/external/users/{id}',
  getUserDocuments: '/user-service/api/external/users/{id}/documents',
  changeLockStatus: '/user-service/api/external/users/{id}/lockStatus',
  updateRoles: '/user-service/api/external/agents/{id}/roles',
  changePassword: '/user-service/api/external/users/{id}/changePassword',
  commonBuyer: {
    createCommonBuyer: '/user-service/api/external/commonBuyers',
    updatePriceType: '/user-service/api/external/commonBuyers/{id}/priceType/{priceTypeId}',
    getCommonBuyer: '/user-service/api/external/commonBuyers/{id}',
    getBuyerOrdersInfo: '/order-service/rest/api/external/buyers/{id}/info',
    orderStatistics: '/order-service/rest/api/external/buyers/{id}/orderStatistics',
  },
  commonSeller: {
    createCommonSeller: '/user-service/api/external/commonSellers/',
    getCommonSeller: '/user-service/api/external/commonSellers/{id}',
    getOrders: '/order-service/rest/api/external/orders',
    getSellerStatistics: '/web-api/sellerOrders/{id}/statistics',
    updateCommonSeller: '/user-service/api/external/commonSellers/{id}',
  },
  commonAgent: {
    createCommonAgent: '/user-service/api/external/commonAgents/',
    getCommonAgent: '/user-service/api/external/commonAgents/{id}',
    updateCommonAgent: '/user-service/api/external/commonAgents/{id}',
    getCommonAgentStatistic: '/user-service/api/external/agents/statistic/{id}',
    getAgentLastOperations: '/user-service/api/external/agents/lastOperations/{id}',
  },
  legalSeller: {
    getSellers: '/user-service/api/external/legalSellers',
  },
  courier: {
    createCourier: '/user-service/api/external/couriers',
  },
  otp: {
    get: `/user-service/api/external/public/otpSend`,
    verify: `/user-service/api/external/public/otpVerify`,
  },
} as UsersEndpointSchema;

interface CommonBuyerEndpointsSchema {
  createCommonBuyer: string;
  getCommonBuyer: string;
  getBuyerOrdersInfo: string;
  orderStatistics: string;
  updatePriceType: string;
}

interface CommonSellerEndpointsSchema {
  createCommonSeller: string;
  getCommonSeller: string;
  getOrders: string;
  getSellerStatistics: string;
  updateCommonSeller: string;
}

interface CommonAgentEndpointsSchema {
  createCommonAgent: string;
  getCommonAgent: string;
  updateCommonAgent: string;
  getCommonAgentStatistic: string;
  getAgentLastOperations: string;
}

interface CourierEndpointsSchema {
  createCourier: string;
}

export interface UsersEndpointSchemaBase {
  getUsers: string;
  createUser: string;
  getUser: string;
  updateUser: string;
  getUserDocuments: string;
  changeLockStatus: string;
  changePassword: string;
  updateRoles: string;
  user: UserScheme;
  merchantUser: any;
  otp: OtpSchema;
}

export interface OtpSchema {
  get: string;
  verify: string;
}

export interface LegalSellerEndpointsSchema {
  getSellers: string;
}

interface UserScheme {
  agent: string;
  changePassword: string;
  roles: string;
  get: string;
  updateAgent: string;
  getSelf: string;
}

export interface UsersEndpointSchema extends UsersEndpointSchemaBase {
  commonBuyer: CommonBuyerEndpointsSchema;
  commonSeller: CommonSellerEndpointsSchema;
  commonAgent: CommonAgentEndpointsSchema;
  legalSeller: LegalSellerEndpointsSchema;
  courier: CourierEndpointsSchema;
}

export type UsersEndpointSchemaInline = UsersEndpointSchemaBase &
  CommonBuyerEndpointsSchema &
  CommonSellerEndpointsSchema &
  CommonAgentEndpointsSchema;
